import React, { useEffect, useRef, useState, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useLocation } from "react-router-dom";
import { Drawer } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faCheck } from "@fortawesome/free-solid-svg-icons";
import ParamsContext from "../useContext/paramContext";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ContinueAs from "../loginWithEmail/continueAs";
import Endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import CartSidebar from "../productDetails/CartSidebar";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import Toast from "../ReactToastify/Toast";
import Radio from "@mui/material/Radio";
import HomeSkeleton from "./HomeSkeleton";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import NoProductsAvailable from "../../Assets/NoProductsAvailable.webp";

const Card = React.lazy(() => import("../Card/Card"));
import {
  getAllBrandList,
  getAllCategoriesData,
  getAllPriceData,
} from "../../resuableFunctions/resuableFunctions";
import {
  getDataFromCookies,
  setDataToCookiesWithExpirationTime,
  removeDataFromCookie,
} from "../Cookies/Cookies";

import "./products.css";
import "./filter.css";
import "./filterSidebar.css";
import { Helmet } from "react-helmet";

const API_HOST = process.env.REACT_APP_API_HOST;
const ORG_NAME = process.env.REACT_APP_NAME;
const infra = process.env.REACT_APP_INFRA;
var ageData = [];
var conditionData = [];
var priceData = [];
let activeFilterParameter = [];
let filterParamsUpdated = [
  {
    columnName: "brand_id",
    values: [],
  },
  {
    columnName: "category_id",
    values: [],
  },
  {
    columnName: "age_group",
    values: [],
  },
  {
    columnName: "condition",
    values: [],
  },
  {
    columnName: "price_range",
    values: [],
  },
  {
    columnName: "sort",
    values: [],
  },
];
const sortData = [
  {
    name: "sortProductsByNameOrderAsc",
    checked: false,
    label: "By Name: A-Z",
  },
  {
    name: "sortProductsByNameOrderDesc",
    checked: false,
    label: "By Name: Z-A",
  },
  {
    name: "sortProductsByPriceOrderAsc",
    checked: false,
    label: "By Price: Low - High",
  },
  {
    name: "sortProductsByPriceOrderDesc",
    checked: false,
    label: "By Price: High - Low",
  },
  {
    name: "sortProductsByDateOrderAsc",
    checked: false,
    label: "By Date: Old - New",
  },
  {
    name: "sortProductsByDateOrderDesc",
    checked: false,
    label: "By Date: New - Old",
  },
];

const ProductList = () => {
  const { showToast } = Toast();
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;

  const [maintainanceMode, setMaintainanceMode] = useState(false);
  let [productsLoading, setproductsLoading] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  let [totalFilters, setTotalFilters] = useState(0);

  const fetchNextUsers = () => {
    let nextPage = multiFilters.page_no + 1;
    if (multiFilters.page_no !== nextPage) {
      setMultiFilters((prev) => ({
        ...prev,
        page_no: nextPage,
      }));
    }
  };

  const navigate = useNavigate();
  const [showAllBrandList, setShowAllBrandList] = useState(false);
  const [noProductsForFilter, setNoProductsForFilter] = useState({
    categoriesData: false,
    brandsList: false,
  });
  const [brandSearch, setBrandSearch] = useState({
    inputBoxEnable: false,
    inputValue: "",
    brandsList: [],
  });
  const [categorySearch, setCategorySearch] = useState({
    inputBoxEnable: false,
    inputValue: "",
    categoryList: [],
  });

  const setConditionData = (conditions) => {
    const conditionsArray = conditions;
    conditionData = [];
    conditionsArray.map((condition) => {
      conditionData.push({
        name: condition,
        checked: false,
        label: condition,
      });
    });
    setCheckedUncheckedForCondition();
  };

  const setPriceData = (prices) => {
    if (prices) {
      const priceArray = prices;
      priceData = [];
      priceArray.map((price) => {
        priceData.push({
          name: `${price.min}-${price.max}`,
          checked: false,
          label: `₹ ${price.min}-${price.max}`,
        });
      });

      setCheckedUncheckedForPriceData();
    }
  };

  const setAgeData = (ageGroup) => {
    if (ageGroup) {
      ageData = [];
      ageGroup.map((age) => {
        (age.value = `${age.min}-${age.max}`), (age["checked"] = false);
        ageData.push(age);
      });
      setCheckedUncheckedForAge();
    }
  };
  const getAgeTitle = (name) => {
    let resName = "";
    ageData &&
      ageData.map((age) => {
        if (age.value == name) {
          resName = age.title;
          return;
        }
      });
    return resName;
  };
  const [showFeatures, setShowFeatures] = useState({
    showWishList: true,
    showSortBy: true,
    showFilter: true,
    showBrandFilter: true,
    showCategoryFilter: true,
    showAgeFilter: true,
    showConditionFilter: true,
    showPriceFilter: true,
    cartSidebar: true,
    flagsUpdated: false,
  });

  const contextObj = useContext(ParamsContext);
  function getIndexOfColumn(columnName) {
    let columnIndex = -1;
    for (let i = 0; i <= filterParamsUpdated.length; i++) {
      if (filterParamsUpdated[i].columnName === columnName) {
        columnIndex = i;
        break;
      }
    }
    return columnIndex;
  }

  function getNameById(id, columnName) {
    let array;
    if (columnName == "category_id") {
      array = JSON.parse(sessionStorage.getItem("categoryListData"));
    } else if (columnName == "brand_id") {
      array = JSON.parse(sessionStorage.getItem("brandsListData"));
    } else {
      return;
    }

    array = array.data;
    const item = array.find((obj) => obj.id == id);
    return item ? item.name : null;
  }

  function setCheckedUncheckedForBrandData() {
    const index = getIndexOfColumn("brand_id");
    const brandDataValues = filterParamsUpdated[index].values;

    brandData.forEach((element) => {
      const activeID = element.id;

      element.checked = brandDataValues.includes(activeID) ? true : false;
    });
    setBrandData(brandData);
  }

  function setCheckedUncheckedForCategoryData() {
    const index = getIndexOfColumn("category_id");

    const categoryDataValues = filterParamsUpdated[index].values;
    if (categoriesData !== undefined) {
      categoriesData.forEach((element) => {
        const activeID = element.id;
        element.checked = categoryDataValues.includes(activeID.toString())
          ? true
          : false;
      });
    }
  }
  function setCheckedUncheckedForAge() {
    const index = getIndexOfColumn("age_group");
    const ageDataValues = filterParamsUpdated[index].values;
    ageData.forEach((element) => {
      const activeName = element.value;
      element.checked = ageDataValues.includes(activeName) ? true : false;
    });
  }

  function setCheckedUncheckedForSort() {
    const index = getIndexOfColumn("sort");
    const sortDataValues = filterParamsUpdated[index].values;

    sortData.forEach((element) => {
      const activeName = element.name;
      element.checked = sortDataValues.includes(activeName) ? true : false;
    });
  }

  function setCheckedUncheckedForCondition() {
    const index = getIndexOfColumn("condition");
    const conditionDataValues = filterParamsUpdated[index].values;
    conditionData.forEach((element) => {
      const activeName = element.name;
      element.checked = conditionDataValues.includes(activeName) ? true : false;
    });
  }

  function setCheckedUncheckedForPriceData() {
    const index = getIndexOfColumn("price_range");
    const priceDataValues = filterParamsUpdated[index].values;
    priceData.forEach((element) => {
      const activeName = element.name;
      element.checked = priceDataValues.includes(activeName) ? true : false;
    });
  }

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isSortOpen, setSortOpen] = useState(false);

  const toggleFilterDrawer = () => {
    setFilterOpen(!isFilterOpen);
  };

  const toggleSortDrawer = () => {
    setSortOpen(!isSortOpen);
  };

  const toggleFilterDrawerClose = () => {
    setFilterOpen(false);
  };

  const [scroll, setScroll] = useState(false);
  const [urlParams, setUrlParams] = useState("");
  const [loading, setLoading] = useState(true);
  const [categoriesData, setCategoriesData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [showGuestPopup, setShowGestPopup] = useState(false);
  const pageTopRef = useRef(null);
  const [filterExpanded, setFilterExpanded] = useState({
    brand: true,
    age: false,
    condition: false,
    price: false,
    sort: false,
    availability: false,
  });
  const [fetchDataNow, setFetchDataNow] = useState(false);
  const [DrawerSelectedSort, setDrawerSelectedSort] = useState({
    Price_Asc: false,
    Price_Desc: false,
    CreatedDate_Asc: false,
    CreatedDate_Desc: true,
  });
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const filteredOptions = filterArray(
      brandData,
      "name",
      brandSearch.inputValue.toLowerCase()
    );
    setBrandSearch((prev) => ({
      ...prev,
      brandsList: filteredOptions,
    }));

    setCheckedUncheckedForBrandData();
  }, [brandData]);

  useEffect(() => {
    const filteredOptions = filterArray(
      categoriesData,
      "name",
      categorySearch.inputValue.toLowerCase()
    );

    setCategorySearch((prev) => ({
      ...prev,
      categoryList: filteredOptions,
    }));
    setCheckedUncheckedForCategoryData();
  }, [categoriesData]);

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showWishList: featureFlags.buyer_products_wishlist,
        showBrandFilter:
          featureFlags.buyer_products_brands &&
          featureFlags.buyer_products_allfilters,
        showCategoryFilter: featureFlags.buyer_products_categories,
        showAgeFilter:
          featureFlags.buyer_products_age &&
          featureFlags.buyer_products_allfilters,
        showConditionFilter:
          featureFlags.buyer_products_condition &&
          featureFlags.buyer_products_allfilters,
        showPriceFilter:
          featureFlags.buyer_products_price &&
          featureFlags.buyer_products_allfilters,
        showSortBy: featureFlags.buyer_products_sortby,
        cartSidebar: featureFlags.buyer_common_addtocartbar,
        showFilter: featureFlags.buyer_products_allfilters,
      }));

      if (
        featureFlags.buyer_products_allfilters &&
        (featureFlags.buyer_products_brands ||
          // featureFlags.buyer_products_categories ||
          featureFlags.buyer_products_age ||
          featureFlags.buyer_products_condition ||
          featureFlags.buyer_products_price ||
          featureFlags.buyer_products_availability)
      ) {
        setShowFeatures((prev) => ({
          ...prev,
          showFilter: featureFlags.buyer_products_allfilters,
        }));
      }
      setShowFeatures((prev) => ({
        ...prev,
        flagsUpdated: true,
      }));
    }
  }, [contextObj.params.featureFlags]);
  useEffect(() => {
    if (showFeatures.flagsUpdated) {
      frameActiveFilterArray();
    }
  }, [showFeatures.flagsUpdated]);
  useEffect(() => {
    if (contextObj.params.showCartSidebar && showFeatures.cartSidebar) {
      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [contextObj.params.showCartSidebar, showFeatures.cartSidebar]);
  useEffect(() => {
    const session_filterParamsUpdated = sessionStorage.getItem(
      "filterParamsUpdated"
    );

    if (session_filterParamsUpdated !== null) {
      filterParamsUpdated = JSON.parse(session_filterParamsUpdated);
      frameActiveFilterArray();
    }

    const loadedFirstTime = getDataFromCookies("loadedFirstTime");

    if (loadedFirstTime == null) {
      const indexOfSortColumn = getIndexOfColumn("sort");
      filterParamsUpdated[indexOfSortColumn].values.push(
        "&order=asc&sort=name"
      );
      const expiryDate = new Date();
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      setDataToCookiesWithExpirationTime(
        "loadedFirstTime",
        false,
        expiryDate,
        "/"
      );
    }

    let brandsInCookies = sessionStorage.getItem("brandsListData");
    let conditionInSession = sessionStorage.getItem("getAllConditions");
    let ageInSession = sessionStorage.getItem("getAllAgeGroups");
    let priceInSession = sessionStorage.getItem("getAllPriceData");

    if (brandsInCookies == null || brandsInCookies == undefined) {
      reFetchBrandListData();
    } else {
      brandsInCookies = JSON.parse(brandsInCookies);
      brandsInCookies = brandsInCookies.data;
      setBrandData(brandsInCookies);
    }
    if (conditionInSession == null || conditionInSession == undefined) {
      getAllConditions();
    } else {
      setConditionData(JSON.parse(conditionInSession));
    }
    if (ageInSession === null || ageInSession === undefined) {
      reFetchAgeGroup();
    } else {
      setAgeData(JSON.parse(ageInSession));
    }

    if (priceInSession == null || priceInSession == undefined) {
      refetchPriceData();
    } else {
      priceInSession = JSON.parse(priceInSession);
      setPriceData(priceInSession);
    }
  }, []);
  const prevFilters = sessionStorage.getItem("multiFilters");
  const initialFilters = prevFilters
    ? JSON.parse(prevFilters)
    : {
        page_no: 1,
        per_page: 20,
      };

  let [multiFilters, setMultiFilters] = useState(initialFilters);
  const handleCloseModal = () => {
    setShowGestPopup(false);
  };

  function frameActiveFilterArray() {
    activeFilterParameter = [];
    filterParamsUpdated.forEach((element) => {
      const filterValues = element.values;
      filterValues.length > 0 &&
        filterValues.forEach((value) => {
          if (
            (element.columnName == "price_range" &&
              showFeatures.showPriceFilter == true) ||
            (element.columnName == "age_group" &&
              showFeatures.showAgeFilter == true) ||
            (element.columnName == "brand_id" &&
              showFeatures.showBrandFilter == true) ||
            (element.columnName == "condition" &&
              showFeatures.showConditionFilter == true)
          ) {
            activeFilterParameter.push({
              columnName: element.columnName,
              value: value,
            });
          }
        });
    });
  }

  function filterArray(arrayJSON, fieldName, stringToSearch) {
    let filteredArray = [];
    const searchString = stringToSearch.toLowerCase();
    arrayJSON.forEach((element) => {
      if (element[`${fieldName}`].toLowerCase().startsWith(searchString))
        filteredArray.push(element);
    });
    return filteredArray;
  }
  function pushToArray(columnName, value) {
    setMultiFilters((prev) => ({
      ...prev,
      page_no: 1,
    }));
    for (let i = 0; i < filterParamsUpdated.length; i++) {
      if (
        filterParamsUpdated[i].columnName === columnName &&
        !filterParamsUpdated[i].values.includes(value)
      ) {
        filterParamsUpdated[i].values.push(value);
        break;
      }
    }
    sessionStorage.setItem(
      "filterParamsUpdated",
      JSON.stringify(filterParamsUpdated)
    );
    frameActiveFilterArray();
    frameQueryString();
  }

  function removeFromArray(columnName, value) {
    setMultiFilters((prev) => ({
      ...prev,
      page_no: 1,
    }));
    const index = getIndexOfColumn(columnName);
    if (filterParamsUpdated[index].values.includes(value)) {
      const curreIndex = filterParamsUpdated[index].values.indexOf(value, 0);
      filterParamsUpdated[index].values.splice(curreIndex, 1);
    }
    sessionStorage.setItem(
      "filterParamsUpdated",
      JSON.stringify(filterParamsUpdated)
    );
    frameActiveFilterArray();
    frameQueryString();
  }

  function removeAllElementsFromAllColumns() {
    filterParamsUpdated.forEach((element) => {
      element.values = [];
    });
    sessionStorage.setItem(
      "filterParamsUpdated",
      JSON.stringify(filterParamsUpdated)
    );
  }

  function frameQueryString() {
    if (showFeatures.flagsUpdated) {
      setproductsLoading(true);
      let queryString = "";
      let filterCount = 0;
      let tempArray = [];
      for (let i = 0; i < filterParamsUpdated.length; i++) {
        if (
          filterParamsUpdated[i].values.length != 0 &&
          filterParamsUpdated[i].columnName != "sort"
        ) {
          tempArray = filterParamsUpdated[i].values;
          if (tempArray.length > 0) {
            for (let j = 0; j < tempArray.length; j++) {
              if (
                (filterParamsUpdated[i].columnName == "price_range" &&
                  showFeatures.showPriceFilter == true) ||
                (filterParamsUpdated[i].columnName == "age_group" &&
                  showFeatures.showAgeFilter == true) ||
                (filterParamsUpdated[i].columnName == "brand_id" &&
                  showFeatures.showBrandFilter == true) ||
                (filterParamsUpdated[i].columnName == "condition" &&
                  showFeatures.showConditionFilter == true) ||
                (filterParamsUpdated[i].columnName == "category_id" &&
                  showFeatures.showCategoryFilter == true)
              ) {
                queryString +=
                  j == 0
                    ? `&${filterParamsUpdated[i].columnName}=${tempArray[j]}`
                    : `,${tempArray[j]}`;
                if (tempArray[j] !== "") {
                  filterCount++;
                }
              }
            }
          }
        }
      }
      if (filterCount > 0) {
        setTotalFilters(filterCount);
        setFilterApplied(true);
      } else {
        setFilterApplied(false);
      }
      const sortColumnIndex = getIndexOfColumn("sort");

      if (showFeatures.showSortBy) {
        if (filterParamsUpdated[sortColumnIndex].values[0]) {
          queryString += filterParamsUpdated[sortColumnIndex].values[0];
        } else {
          queryString += "&order=desc&sort=created_at";
        }
      }

      setUrlParams(queryString);
      multiFilters.page_no = 1;
      fetchProducts(queryString);
    }
  }

  const clearFilter = async () => {
    sessionStorage.removeItem("selectedCategoryId");
    setNoProductsForFilter((prev) => ({
      ...prev,
      categoriesData: false,
      brandsList: false,
    }));
    setCategorySearch((prev) => ({
      ...prev,
      inputBoxEnable: false,
      inputValue: "",
      categoryList: categoriesData,
    }));

    setBrandSearch((prev) => ({
      ...prev,
      inputBoxEnable: false,
      inputValue: "",
      brandsList: brandData,
    }));

    activeFilterParameter = [];

    setSelectedOption((prev) => ({
      ...prev,
      name: "Newest First",
      value: "&order=desc&sort=created_at",
    }));
    setDrawerSelectedSort((prev) => ({
      ...prev,
      Price_Asc: false,
      Price_Desc: false,
      CreatedDate_Asc: false,
      CreatedDate_Desc: true,
    }));
    removeAllElementsFromAllColumns();
    setUrlParams("");
    setCheckedUncheckedForBrandData();
    setCheckedUncheckedForCategoryData();
    setCheckedUncheckedForAge();
    setCheckedUncheckedForCondition();
    setCheckedUncheckedForPriceData();
    setCheckedUncheckedForSort();
    sessionStorage.removeItem("filterParamsUpdated");

    removeDataFromCookie("loadedFirstTime", "/");

    defaultRender = true;

    contextObj.setInput(
      "resetSelectedCategoryId",
      !contextObj.params.resetSelectedCategoryId
    );

    frameQueryString();
  };
  const categoryFilterApplied = async () => {
    const session_filterParamsUpdated = sessionStorage.getItem(
      "filterParamsUpdated"
    );
    if (session_filterParamsUpdated != null) {
      filterParamsUpdated = JSON.parse(session_filterParamsUpdated);

      frameQueryString();
    }
  };

  useEffect(() => {
    if (contextObj.params.reSetFilters) {
      clearFilter();
      contextObj.setInput("reSetFilters", false);
    }
    if (contextObj.params.categoryFilterApplied) {
      categoryFilterApplied();
      contextObj.setInput("categoryFilterApplied", false);
    }
  }, [contextObj.params.reSetFilters, contextObj.params.categoryFilterApplied]);
  let [defaultRender, setDefaultRender] = useState(true);
  const fetchProducts = (urlParams) => {
    defaultRender = false;
    let url = `${API_HOST}/${Endpoints.getProducts}?${urlParams}&page_no=${multiFilters.page_no}&per_page=${multiFilters.per_page}`;

    let headers;
    if (getDataFromCookies("isLoggedIn")) {
      const authenticationToken = getDataFromCookies("7H3K9R5P2Q") || "";
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticationToken}`,
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    fetch(`${url}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status == 200) {
          if (multiFilters.page_no == 1) {
            setProducts([]);
          }
          const response = res.data;
          if (response.current_page < response.last_page) {
            setScroll(true);
          } else {
            setScroll(false);
          }
          if (response.data) {
            const productsDataRes = response.data;
            if (productsDataRes) {
              setProducts((prevProducts) => [
                ...prevProducts,
                ...productsDataRes,
              ]);
            }
          }
        } else {
          if (multiFilters.page_no == 1) {
            setProducts([]);
          }
        }
        setLoading(false);
        setproductsLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
        showToast("error", err);
      });
  };

  // get conditions
  const getAllConditions = async () => {
    await fetch(`${API_HOST}/${Endpoints.getProductConditions}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (
          data.status == 500 ||
          data.status == 501 ||
          data.status == 502 ||
          data.status == 503 ||
          (data.message && data.message.toLowerCase().includes("server error"))
        ) {
          if (data.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (data.status == 200) {
          let conditions = data.data;
          const titlesArray = conditions.map((item) => item.title);
          sessionStorage.setItem(
            "getAllConditions",
            JSON.stringify(titlesArray)
          );
          setConditionData(titlesArray);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      });
  };

  // get age Group details
  const getAllAgeGroups = async () => {
    await fetch(`${API_HOST}/${Endpoints.getApplicableAgeGroup}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (
          data.status == 500 ||
          data.status == 501 ||
          data.status == 502 ||
          data.status == 503 ||
          (data.message && data.message.toLowerCase().includes("server error"))
        ) {
          if (data.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (data.status == 200) {
          let ageGroups = data.data;
          setAgeData(ageGroups);
          sessionStorage.setItem("getAllAgeGroups", JSON.stringify(ageGroups));
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      });
  };

  const reFetchBrandListData = async () => {
    await getAllBrandList();
    let brandsInCookies = sessionStorage.getItem("brandsListData");
    if (brandsInCookies) {
      brandsInCookies = JSON.parse(brandsInCookies);
      brandsInCookies = brandsInCookies.data;
      setBrandData(brandsInCookies);
    }
  };
  const reFetchAgeGroup = async () => {
    await getAllAgeGroups();
    // let ageInSession = sessionStorage.getItem("getAllAgeGroups");
    // setAgeData(ageInSession);
  };
  const refetchPriceData = async () => {
    let serviceUnavailable = await getAllPriceData();
    if (serviceUnavailable) {
      contextObj.setInput("serviceUnavailable", true);
    }
    let priceInSession = sessionStorage.getItem("getAllPriceData");
    if (priceInSession) {
      priceInSession = JSON.parse(priceInSession);
      setPriceData(priceInSession);
    }
  };

  useEffect(() => {
    if (multiFilters.page_no !== 1) {
      fetchProducts(urlParams);
    }
  }, [multiFilters.page_no]);

  useEffect(() => {
    if (contextObj.params.isLoggedIn || defaultRender) {
      contextObj.setInput("showCartSidebar", false);
      setMultiFilters((prev) => ({
        ...prev,
        page_no: 1,
      }));
      window.scrollTo(0, 0);
      const sortColumnIndex = getIndexOfColumn("sort");

      filterParamsUpdated[sortColumnIndex].values = [];
      defaultRender = false;
      frameQueryString();
    }
  }, [contextObj.params.isLoggedIn, showFeatures.flagsUpdated]);

  const productListNavigation = async () => {
    if (
      contextObj.params.navigation === "true" &&
      contextObj.params.navigationFrom === "sessionExpired"
    ) {
      contextObj.setInput("showLoginModel", true);
      contextObj.setInput("navigation", "false");
      contextObj.setInput("navigationFrom", "");
    }

    let brandsInCookies = sessionStorage.getItem("brandsListData");

    let categoryInCookies = sessionStorage.getItem("categoryListData");
    if (brandsInCookies == null || brandsInCookies == undefined) {
      let serviceUnavailable = await getAllBrandList();
      if (serviceUnavailable) {
        contextObj.setInput("serviceUnavailable", true);
      }
    } else {
      brandsInCookies = JSON.parse(brandsInCookies);
      brandsInCookies = brandsInCookies.data;
      setBrandData(brandsInCookies);
    }
    if (categoryInCookies == null) {
      let serviceUnavailable = await getAllCategoriesData();
      if (serviceUnavailable) {
        contextObj.setInput("serviceUnavailable", true);
      }
    } else {
      categoryInCookies = JSON.parse(categoryInCookies);
      categoryInCookies = categoryInCookies.data;
      setCategoriesData(categoryInCookies);
    }
  };
  useEffect(() => {
    productListNavigation();
  }, [contextObj.params.navigation]);

  const [selectedOption, setSelectedOption] = useState({
    name: "Newest First",
    value: "&order=desc&sort=created_at",
    showSort: false,
  });

  const handleOptionChange = (value) => {
    const indexOfSortColumn = getIndexOfColumn("sort");
    if (indexOfSortColumn >= 0) {
      filterParamsUpdated[indexOfSortColumn].values = [];
      filterParamsUpdated[indexOfSortColumn].values.push(value);
      frameQueryString();
    }
  };

  function handleOptionChangemediaquery(option) {
    const indexOfSortColumn = getIndexOfColumn("sort");

    if (indexOfSortColumn >= 0) {
      const updatedParams = [...filterParamsUpdated];
      updatedParams[indexOfSortColumn].values = [option];
      window.scrollTo(0, 0);
      frameQueryString(updatedParams);
    }
  }
  const [triggerShowAlert, setTriggerShowAlert] = useState(false);
  useEffect(() => {
    if (triggerShowAlert) {
      setTriggerShowAlert(false);
    }
  }, [triggerShowAlert]);

  // footer sticky
  const [isFilterSticky, setIsFilterSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const listEnd = document.getElementById("product-list-end");
      const filterBar = document.getElementById("filterBar");

      if (filterBar && listEnd) {
        const isSticky =
          window.scrollY + window.innerHeight >= listEnd.offsetTop;
        setIsFilterSticky(isSticky);
        setMobileViewMargin(!isSticky);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // setStickyFilterWeb
  const [FilterContainerCss, setFilterContainerCss] = useState({
    top: 0,
    bottom: 0,
    currentScrollY: 0,
  });
  useEffect(() => {
    const stickyFilter = () => {
      const FilterList = document.getElementById("FilterContainer");
      if (FilterList && navbarBodyContainer) {
        var filterElementRect = FilterList.getBoundingClientRect();
        var filterElementTop = filterElementRect.top;

        var viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;

        if (FilterList.offsetHeight + 110 > viewportHeight) {
          if (window.scrollY > FilterContainerCss.currentScrollY) {
            setFilterContainerCss((prev) => ({
              ...prev,
              top: viewportHeight - FilterList.offsetHeight,
              currentScrollY: window.scrollY,
            }));
          } else {
            let reduce =
              FilterContainerCss.top +
              (FilterContainerCss.currentScrollY - window.scrollY);

            setFilterContainerCss((prev) => ({
              ...prev,
              top: reduce > 120 ? 120 : reduce,
              currentScrollY: window.scrollY,
            }));
          }
        } else {
          setFilterContainerCss((prev) => ({
            ...prev,
            top: FilterContainerCss.top == 0 ? filterElementTop : 120,
            currentScrollY: window.scrollY,
          }));
        }
      }
    };

    window.addEventListener("scroll", stickyFilter);
    return () => window.removeEventListener("scroll", stickyFilter);
  }, [window.scrollY]);

  const [mobileViewMargin, setMobileViewMargin] = useState(false);

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>Discover Joyful Treasures - JOYREJOY Products</title>
          <meta
            name="description"
            content="Explore the world of joy with our playful product listings at JOYREJOY! Dive into a treasure trove of preloved and new items for kids, handpicked with love and care. Let's make shopping a delightful adventure!"
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <div
          className="pageParentDiv"
          style={{
            marginBottom:
              mobileViewMargin && window.innerWidth <= 600 && "-50px",
          }}
        >
          {loading ? (
            <div className="homepageContainer">
              <div className="home-Page-skeleton-view">
                <HomeSkeleton />
              </div>
            </div>
          ) : (
            <>
              <div
                ref={pageTopRef}
                className={loading ? "loader-cursor" : "homepageContainer"}
              >
                <div className="FilterContainerMobile">
                  <div className="FilterContainerMobile-bottom-element">
                    test
                  </div>
                  <div className="FilterContainerMobile-bottom-element">
                    <FontAwesomeIcon
                      className="fa-filter-icon"
                      icon={faFilter}
                    />
                  </div>
                </div>

                <div className="ProductListContainer">
                  {showFeatures.showFilter &&
                    (showFeatures.showBrandFilter ||
                      // showFeatures.showCategoryFilter ||
                      showFeatures.showAgeFilter ||
                      showFeatures.showConditionFilter ||
                      showFeatures.showPriceFilter) && (
                      <div className="FilterContainerHolder">
                        <div
                          className="FilterContainer"
                          id="FilterContainer"
                          style={{
                            top: `${FilterContainerCss.top}px`,
                            bottom: `${FilterContainerCss.bottom}px`,
                          }}
                        >
                          <div>
                            <div
                              className="filter"
                              id="clearFilterBtnContainer"
                            >
                              <div className="">
                                <p className="filter-para-tag">FILTERS</p>
                              </div>
                              <div
                                className={`clearFilterBtnContainer-c1 ${
                                  filterApplied ? "fade-in" : "fade-out"
                                }`}
                              >
                                <button
                                  className="clearFilterBtn"
                                  onClick={() => {
                                    if (filterApplied) {
                                      clearFilter();
                                    }
                                  }}
                                >
                                  CLEAR ALL
                                </button>
                              </div>
                            </div>

                            <div className="filterHolder">
                              {showFeatures.showPriceFilter &&
                                priceData.length > 0 && (
                                  <div className="filter-container">
                                    <div className="filter">
                                      <div className="filter-header">
                                        <h3
                                          className="allsortname"
                                          id="priceFilterHeader"
                                        >
                                          PRICE
                                        </h3>
                                      </div>

                                      <div
                                        className="filter-values"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <div>
                                          {priceData.map((price, index) => (
                                            <div
                                              key={index}
                                              className="filter-product-age"
                                            >
                                              <label
                                                className="filterCheckboxLabel"
                                                id={`priceFilter_${index}`}
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    const checkbox =
                                                      document.getElementById(
                                                        `price_${price.name
                                                          .split(" ")
                                                          .join("")}`
                                                      );
                                                    checkbox.checked =
                                                      !checkbox.checked;
                                                    if (checkbox.checked) {
                                                      pushToArray(
                                                        "price_range",
                                                        checkbox.value
                                                      );
                                                    } else {
                                                      removeFromArray(
                                                        "price_range",
                                                        checkbox.value
                                                      );
                                                    }
                                                    setCheckedUncheckedForPriceData();
                                                  }
                                                }}
                                              >
                                                <div
                                                  className={
                                                    price.checked
                                                      ? "filterCheckboxBox checked"
                                                      : "filterCheckboxBox"
                                                  }
                                                >
                                                  {price.checked && (
                                                    <FontAwesomeIcon
                                                      icon={faCheck}
                                                      className="fa-check-icons-product"
                                                    />
                                                  )}
                                                </div>
                                                <input
                                                  id={`price_${price.name
                                                    .split(" ")
                                                    .join("")}`}
                                                  name={price.name}
                                                  type="checkbox"
                                                  value={price.name}
                                                  className="input-checkobox-op"
                                                  checked={price.checked}
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      pushToArray(
                                                        "price_range",
                                                        e.target.value
                                                      );
                                                    } else {
                                                      removeFromArray(
                                                        "price_range",
                                                        e.target.value
                                                      );
                                                    }
                                                    setCheckedUncheckedForPriceData();
                                                  }}
                                                />
                                                {price.label}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {showFeatures.showAgeFilter &&
                                ageData.length > 0 && (
                                  <div
                                    id="ageContainerDiv"
                                    className="filter-container"
                                  >
                                    <div className="filter">
                                      <div className="filter-header">
                                        <h3
                                          className="allsortname"
                                          id="ageFilterHeader"
                                        >
                                          AGE GROUP
                                        </h3>
                                      </div>

                                      <div
                                        className="filter-values"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <div>
                                          {ageData.map((age, index) => (
                                            <div
                                              key={index}
                                              className="filter-product-age"
                                            >
                                              <label
                                                className="filterCheckboxLabel"
                                                id={`ageFilter_${index}`}
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    const checkbox =
                                                      document.getElementById(
                                                        `age_${age.title}`
                                                      );
                                                    checkbox.checked =
                                                      !checkbox.checked;
                                                    if (checkbox.checked) {
                                                      pushToArray(
                                                        "age_group",
                                                        checkbox.value
                                                      );
                                                    } else {
                                                      removeFromArray(
                                                        "age_group",
                                                        checkbox.value
                                                      );
                                                    }
                                                    setCheckedUncheckedForAge();
                                                  }
                                                }}
                                              >
                                                <div
                                                  className={
                                                    age.checked
                                                      ? "filterCheckboxBox checked"
                                                      : "filterCheckboxBox"
                                                  }
                                                >
                                                  {age.checked && (
                                                    <FontAwesomeIcon
                                                      icon={faCheck}
                                                      className="fa-check-icons-product"
                                                    />
                                                  )}
                                                </div>

                                                <input
                                                  id={`age_${age.title}`}
                                                  name={age.title}
                                                  type="checkbox"
                                                  value={age.value}
                                                  className="input-checkobox-op"
                                                  checked={age.checked}
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      pushToArray(
                                                        "age_group",
                                                        e.target.value
                                                      );
                                                    } else {
                                                      removeFromArray(
                                                        "age_group",
                                                        e.target.value
                                                      );
                                                    }
                                                    setCheckedUncheckedForAge();
                                                  }}
                                                />
                                                {age.title}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {showFeatures.showConditionFilter &&
                                conditionData.length > 0 && (
                                  <div
                                    id="ConditionContainerDiv"
                                    className="filter-container"
                                  >
                                    <div className="filter">
                                      <div className="filter-header">
                                        <h3
                                          className="allsortname"
                                          id="conditionFilterHeader"
                                        >
                                          PRODUCT CONDITIONS
                                        </h3>
                                      </div>

                                      <div
                                        className="filter-values"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <div>
                                          {conditionData.map(
                                            (condition, index) => (
                                              <div
                                                key={index}
                                                className="filter-product-age"
                                              >
                                                <label
                                                  className="filterCheckboxLabel"
                                                  id={`conditionFilter_${index}`}
                                                  tabIndex={0}
                                                  onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                      const checkbox =
                                                        document.getElementById(
                                                          `condition_${condition.name
                                                            .split(" ")
                                                            .join("")}`
                                                        );
                                                      checkbox.checked =
                                                        !checkbox.checked;
                                                      if (checkbox.checked) {
                                                        pushToArray(
                                                          "condition",
                                                          checkbox.value
                                                        );
                                                      } else {
                                                        removeFromArray(
                                                          "condition",
                                                          checkbox.value
                                                        );
                                                      }
                                                      setCheckedUncheckedForCondition();
                                                    }
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      condition.checked
                                                        ? "filterCheckboxBox checked"
                                                        : "filterCheckboxBox"
                                                    }
                                                  >
                                                    {condition.checked && (
                                                      <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="fa-check-icons-product"
                                                      />
                                                    )}
                                                  </div>
                                                  <input
                                                    id={`condition_${condition.name
                                                      .split(" ")
                                                      .join("")}`}
                                                    name={condition.label}
                                                    type="checkbox"
                                                    value={condition.label}
                                                    className="input-checkobox-op"
                                                    checked={condition.checked}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        pushToArray(
                                                          "condition",
                                                          e.target.value
                                                        );
                                                      } else {
                                                        removeFromArray(
                                                          "condition",
                                                          e.target.value
                                                        );
                                                      }
                                                      setCheckedUncheckedForCondition();
                                                    }}
                                                  />
                                                  {condition.label}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {showFeatures.showBrandFilter &&
                                brandData.length > 0 && (
                                  <div className="filter-container">
                                    <div className="filter">
                                      <div className="filter-header Brand_Filter_head">
                                        {!brandSearch.inputBoxEnable ? (
                                          <>
                                            <div>
                                              <h3
                                                className="allsortname"
                                                id="brandFilterHeader"
                                              >
                                                BRANDS
                                              </h3>
                                            </div>
                                            <div
                                              className="enableBrandSearchFilter"
                                              tabIndex="0" // Add tabIndex here
                                              onClick={() => {
                                                setBrandSearch((prev) => ({
                                                  ...prev,
                                                  inputBoxEnable: true,
                                                  inputValue: "",
                                                }));
                                                setShowAllBrandList(true);
                                              }}
                                              onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                  setBrandSearch((prev) => ({
                                                    ...prev,
                                                    inputBoxEnable: true,
                                                    inputValue: "",
                                                  }));
                                                  setShowAllBrandList(true);
                                                }
                                              }}
                                            >
                                              <svg
                                                width="25"
                                                height="25"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <circle
                                                  cx="9"
                                                  cy="9"
                                                  r="9"
                                                  fill="#E4E4E4"
                                                />
                                                <path
                                                  d="M8.54453 10.8C10.2842 10.8 11.6945 9.3897 11.6945 7.65C11.6945 5.9103 10.2842 4.5 8.54453 4.5C6.80483 4.5 5.39453 5.9103 5.39453 7.65C5.39453 9.3897 6.80483 10.8 8.54453 10.8Z"
                                                  stroke="#535353"
                                                  strokeLinecap="round" // Corrected attribute name
                                                  strokeLinejoin="round" // Corrected attribute name
                                                />
                                                <path
                                                  d="M13.4969 12.5989L10.7969 9.89893"
                                                  stroke="#535353"
                                                  strokeLinecap="round" // Corrected attribute name
                                                  strokeLinejoin="round" // Corrected attribute name
                                                />
                                              </svg>
                                            </div>
                                          </>
                                        ) : (
                                          <div className="FilterSearchbarContainer filterSearchbar">
                                            <div className="filterSearchbarIputContainer">
                                              <input
                                                type="text"
                                                autoFocus
                                                placeholder="Search Brands"
                                                className="filterBrandsName"
                                                value={brandSearch.inputValue}
                                                tabIndex="0" // Add tabIndex here
                                                onChange={(e) => {
                                                  let str =
                                                    e.target.value.toLowerCase();
                                                  if (
                                                    str.length > 2 ||
                                                    str.length === 0
                                                  ) {
                                                    const filteredOptions =
                                                      filterArray(
                                                        brandData,
                                                        "name",
                                                        str
                                                      );
                                                    setBrandSearch((prev) => ({
                                                      ...prev,
                                                      inputValue:
                                                        e.target.value,
                                                      brandsList:
                                                        filteredOptions,
                                                    }));
                                                    if (
                                                      filteredOptions.length ===
                                                      0
                                                    ) {
                                                      setNoProductsForFilter(
                                                        (prev) => ({
                                                          ...prev,
                                                          brandsList: true,
                                                        })
                                                      );
                                                    } else {
                                                      setNoProductsForFilter(
                                                        (prev) => ({
                                                          ...prev,
                                                          brandsList: false,
                                                        })
                                                      );
                                                    }
                                                  } else {
                                                    setBrandSearch((prev) => ({
                                                      ...prev,
                                                      inputValue:
                                                        e.target.value,
                                                    }));
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div className="crossbuttonBrandsFilter">
                                              <FontAwesomeIcon
                                                icon={faXmark}
                                                className="fa-x-mark-icons"
                                                onClick={() => {
                                                  setNoProductsForFilter(
                                                    (prev) => ({
                                                      ...prev,
                                                      brandsList: false,
                                                    })
                                                  );
                                                  setBrandSearch((prev) => ({
                                                    ...prev,
                                                    inputBoxEnable: false,
                                                    inputValue: "",
                                                    brandsList: brandData,
                                                  }));
                                                  setShowAllBrandList(false);
                                                }}
                                                tabIndex="0" // Add tabIndex here
                                                onKeyPress={(e) => {
                                                  if (e.key === "Enter") {
                                                    setNoProductsForFilter(
                                                      (prev) => ({
                                                        ...prev,
                                                        brandsList: false,
                                                      })
                                                    );
                                                    setBrandSearch((prev) => ({
                                                      ...prev,
                                                      inputBoxEnable: false,
                                                      inputValue: "",
                                                      brandsList: brandData,
                                                    }));
                                                    setShowAllBrandList(false);
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        className="filter-values"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        {noProductsForFilter.brandsList && (
                                          <p>No Brands Found</p>
                                        )}
                                        {brandSearch.brandsList &&
                                          (showAllBrandList ? (
                                            <div>
                                              {brandSearch.brandsList.map(
                                                (brand, index) => (
                                                  <div
                                                    key={brand.id}
                                                    className="filter-product-age"
                                                  >
                                                    <label
                                                      className="filterCheckboxLabel"
                                                      id={`brandFilter_${index}`}
                                                      tabIndex="0" // Add tabIndex here
                                                      onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                          brand.checked =
                                                            !brand.checked;
                                                          if (brand.checked) {
                                                            pushToArray(
                                                              "brand_id",
                                                              brand.id
                                                            );
                                                            setCheckedUncheckedForBrandData();
                                                          } else {
                                                            removeFromArray(
                                                              "brand_id",
                                                              brand.id
                                                            );
                                                            setCheckedUncheckedForBrandData();
                                                          }
                                                        }
                                                      }}
                                                    >
                                                      <div
                                                        className={
                                                          brand.checked
                                                            ? "filterCheckboxBox checked"
                                                            : "filterCheckboxBox"
                                                        }
                                                      >
                                                        {brand.checked && (
                                                          <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className="fa-check-icons-product"
                                                          />
                                                        )}
                                                      </div>
                                                      <input
                                                        id={`brand_${brand.name}`}
                                                        type="checkbox"
                                                        className="input-checkobox-op"
                                                        checked={brand.checked}
                                                        onChange={async (e) => {
                                                          if (
                                                            e.target.checked
                                                          ) {
                                                            pushToArray(
                                                              "brand_id",
                                                              brand.id
                                                            );
                                                            setCheckedUncheckedForBrandData();
                                                          } else {
                                                            removeFromArray(
                                                              "brand_id",
                                                              brand.id
                                                            );
                                                            setCheckedUncheckedForBrandData();
                                                          }
                                                        }}
                                                      />
                                                      {brand.name}
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          ) : (
                                            <div>
                                              {brandSearch.brandsList
                                                .slice(0, 5)
                                                .map((brand, index) => (
                                                  <div
                                                    key={brand.id}
                                                    className="filter-product-age"
                                                  >
                                                    <label
                                                      className="filterCheckboxLabel"
                                                      id={`brandFilter_${index}`}
                                                      tabIndex={0} // Add tabIndex here
                                                      onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                          brand.checked =
                                                            !brand.checked;
                                                          if (brand.checked) {
                                                            pushToArray(
                                                              "brand_id",
                                                              brand.id
                                                            );
                                                            setCheckedUncheckedForBrandData();
                                                          } else {
                                                            removeFromArray(
                                                              "brand_id",
                                                              brand.id
                                                            );
                                                            setCheckedUncheckedForBrandData();
                                                          }
                                                        }
                                                      }}
                                                    >
                                                      <div
                                                        className={
                                                          brand.checked
                                                            ? "filterCheckboxBox checked"
                                                            : "filterCheckboxBox"
                                                        }
                                                      >
                                                        {brand.checked && (
                                                          <FontAwesomeIcon
                                                            icon={faCheck}
                                                            className="fa-check-icons-product"
                                                          />
                                                        )}
                                                      </div>
                                                      <input
                                                        id={`brand_${brand.name}`}
                                                        // tabIndex={-1}
                                                        type="checkbox"
                                                        className="input-checkobox-op"
                                                        checked={brand.checked}
                                                        onChange={async (e) => {
                                                          if (
                                                            e.target.checked
                                                          ) {
                                                            pushToArray(
                                                              "brand_id",
                                                              brand.id
                                                            );
                                                            setCheckedUncheckedForBrandData();
                                                          } else {
                                                            removeFromArray(
                                                              "brand_id",
                                                              brand.id
                                                            );
                                                            setCheckedUncheckedForBrandData();
                                                          }
                                                        }}
                                                      />
                                                      {brand.name}
                                                    </label>
                                                  </div>
                                                ))}
                                            </div>
                                          ))}

                                        {!showAllBrandList &&
                                          brandSearch.brandsList.length - 5 >
                                            0 && (
                                            <div
                                              className="showMoreFilterDiv"
                                              onClick={() => {
                                                setShowAllBrandList(true);
                                              }}
                                              tabIndex="0" // Add tabIndex here
                                              onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                  setShowAllBrandList(true);
                                                }
                                              }}
                                            >
                                              <p className="showMoreFiltertext">
                                                {" "}
                                                +{" "}
                                                {brandSearch.brandsList.length -
                                                  5}{" "}
                                                more{" "}
                                              </p>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {contextObj.params.showCartSidebar &&
                    showFeatures.cartSidebar && (
                      <div className="WebViewOnly">
                        {" "}
                        <CartSidebar
                          fetchDataNow={fetchDataNow}
                          resetFetchDataNow={setFetchDataNow}
                        />{" "}
                      </div>
                    )}

                  <>
                    <div className="product-container">
                      <div className="filter-container-sort">
                        <div className="filterChipHolder">
                          {/* {console.log(
                            "activeFilterParameter========>",
                            activeFilterParameter
                          )} */}
                          {activeFilterParameter &&
                            activeFilterParameter.map((filter, index) => (
                              <div key={index}>
                                {filter.columnName !== "sort" &&
                                  filter.columnName !== "category_id" && (
                                    <div className="filterChip">
                                      <div className="fliterChipTextContainer">
                                        {filter.columnName == "brand_id" ||
                                        filter.columnName == "category_id" ? (
                                          <p
                                            className="fliterChipText"
                                            id={
                                              filter.columnName == "brand_id"
                                                ? `brandFilterChip_${getNameById(
                                                    filter.value,
                                                    filter.columnName
                                                  )
                                                    .split(" ")
                                                    .join("_")
                                                    .split("-")
                                                    .join("_")}`
                                                : `categoryFilterChip_${filter.value
                                                    .split(" ")
                                                    .join("_")
                                                    .split("-")
                                                    .join("_")}`
                                            }
                                          >
                                            {getNameById(
                                              filter.value,
                                              filter.columnName
                                            )}
                                          </p>
                                        ) : filter.columnName == "age_group" ? (
                                          <p
                                            className="fliterChipText"
                                            id={`ageGroupFilterChip_${filter.value
                                              .split(" ")
                                              .join("_")
                                              .split("-")
                                              .join("_")}`}
                                          >
                                            {getAgeTitle(filter.value)}
                                          </p>
                                        ) : (
                                          <p
                                            className="fliterChipText"
                                            id={
                                              filter.columnName == "condition"
                                                ? `conditionFilterChip_${filter.value
                                                    .split(" ")
                                                    .join("_")
                                                    .split("-")
                                                    .join("_")}`
                                                : `priceRangeFilterChip_${filter.value
                                                    .split(" ")
                                                    .join("_")
                                                    .split("-")
                                                    .join("_")}`
                                            }
                                          >
                                            {filter.columnName == "price_range"
                                              ? `₹ ${filter.value}`
                                              : filter.value == "4%2B%20Years"
                                              ? "4+ Years"
                                              : filter.value == "in_stock"
                                              ? "In Stock"
                                              : filter.value == "sold_out"
                                              ? "Out of Stock"
                                              : filter.value}
                                          </p>
                                        )}
                                      </div>
                                      <div
                                        className="fliterChipRemoveBtnContainer"
                                        onClick={() => {
                                          removeFromArray(
                                            filter.columnName,
                                            filter.value
                                          );
                                          frameQueryString();
                                          if (filter.columnName == "brand_id") {
                                            setCheckedUncheckedForBrandData();
                                          } else if (
                                            filter.columnName == "category_id"
                                          ) {
                                            setCheckedUncheckedForCategoryData();
                                          } else if (
                                            filter.columnName == "age_group"
                                          ) {
                                            setCheckedUncheckedForAge();
                                          } else if (
                                            filter.columnName == "condition"
                                          ) {
                                            setCheckedUncheckedForCondition();
                                          } else if (
                                            filter.columnName == "price_range"
                                          ) {
                                            setCheckedUncheckedForPriceData();
                                          }
                                        }}
                                      >
                                        <svg
                                          width="7"
                                          height="7"
                                          viewBox="0 0 7 7"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M4.32171 3.4995L6.82762 0.999063C6.93736 0.88931 6.99901 0.740453 6.99901 0.585238C6.99901 0.430023 6.93736 0.281166 6.82762 0.171412C6.71788 0.0616588 6.56904 0 6.41385 0C6.25866 0 6.10982 0.0616588 6.00008 0.171412L3.5 2.67768L0.999916 0.171412C0.890178 0.0616588 0.741342 -1.15644e-09 0.586149 0C0.430957 1.15644e-09 0.28212 0.0616588 0.172382 0.171412C0.0626446 0.281166 0.000994557 0.430023 0.000994556 0.585238C0.000994554 0.740453 0.0626446 0.88931 0.172382 0.999063L2.67829 3.4995L0.172382 5.99994C0.11776 6.05413 0.0744057 6.11859 0.0448192 6.18962C0.0152327 6.26064 0 6.33682 0 6.41377C0 6.49071 0.0152327 6.56689 0.0448192 6.63792C0.0744057 6.70894 0.11776 6.77341 0.172382 6.82759C0.226558 6.88222 0.291013 6.92558 0.362029 6.95517C0.433045 6.98477 0.509217 7 0.586149 7C0.663082 7 0.739253 6.98477 0.810269 6.95517C0.881285 6.92558 0.94574 6.88222 0.999916 6.82759L3.5 4.32133L6.00008 6.82759C6.05426 6.88222 6.11872 6.92558 6.18973 6.95517C6.26075 6.98477 6.33692 7 6.41385 7C6.49078 7 6.56696 6.98477 6.63797 6.95517C6.70899 6.92558 6.77344 6.88222 6.82762 6.82759C6.88224 6.77341 6.9256 6.70894 6.95518 6.63792C6.98477 6.56689 7 6.49071 7 6.41377C7 6.33682 6.98477 6.26064 6.95518 6.18962C6.9256 6.11859 6.88224 6.05413 6.82762 5.99994L4.32171 3.4995Z"
                                            fill="#A2A2A2"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            ))}
                        </div>
                        {showFeatures.showSortBy && products.length > 1 && (
                          <div
                            className="select-dropdown-container"
                            onMouseEnter={() => {
                              setSelectedOption((prev) => ({
                                ...prev,
                                showSort: true,
                              }));
                            }}
                            onMouseLeave={() => {
                              setSelectedOption((prev) => ({
                                ...prev,
                                showSort: false,
                              }));
                            }}
                            onClick={() => {
                              setSelectedOption((prev) => ({
                                ...prev,
                                showSort: !selectedOption.showSort,
                              }));
                            }}
                          >
                            <div className="sort-by-container-d1">
                              <div className="sort-by-c1-d1-c1">
                                <p className="noMarginParagraph">Sort By: </p>
                              </div>
                              <div className="selectedSort">
                                <span
                                  id="selectOption"
                                  className="noMarginParagraph"
                                  onChange={(e) =>
                                    handleOptionChange(e.target.value)
                                  }
                                >
                                  {selectedOption.name}{" "}
                                </span>
                                {selectedOption.showSort ? (
                                  <span
                                    className="selectedSortIcon"
                                    id={
                                      selectedOption ==
                                      "&order=asc&sort=selling_price"
                                        ? "sortDropDownOption"
                                        : ""
                                    }
                                  >
                                    <svg
                                      width="10"
                                      height="10"
                                      viewBox="0 0 19 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.5 4.36308L2.11078 12L-7.7486e-07 9.81846L9.5 -2.38419e-07L19 9.81846L16.8892 12L9.5 4.36308Z"
                                        fill="#8C8C8C"
                                      />
                                    </svg>
                                  </span>
                                ) : (
                                  <span className="selectedSortIcon">
                                    <svg
                                      width="10"
                                      height="10"
                                      viewBox="0 0 19 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.5 7.63692L16.8892 0L19 2.18154L9.5 12L0 2.18154L2.11078 0L9.5 7.63692Z"
                                        fill="#8C8C8C"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </div>

                            {selectedOption.showSort && (
                              <div
                                className="sortOptionsContainer"
                                id="sortOptionsContainer"
                              >
                                <div
                                  className="sortByOptionsHolder"
                                  id="sort_newest_first"
                                  onClick={() => {
                                    setSelectedOption((prev) => ({
                                      ...prev,
                                      name: "Newest First",
                                      value: "&order=desc&sort=created_at",
                                    }));
                                    handleOptionChange(
                                      "&order=desc&sort=created_at"
                                    );
                                  }}
                                >
                                  <p className="noMarginParagraph sortByOptions">
                                    Newest First
                                  </p>
                                </div>
                                <div
                                  className="sortByOptionsHolder"
                                  id="sort_oldest_first"
                                  onClick={() => {
                                    setSelectedOption((prev) => ({
                                      ...prev,
                                      name: "Oldest First",
                                      value: "&order=asc&sort=created_at",
                                    }));
                                    handleOptionChange(
                                      "&order=asc&sort=created_at"
                                    );
                                  }}
                                >
                                  <p className="noMarginParagraph sortByOptions">
                                    Oldest First
                                  </p>
                                </div>
                                <div
                                  className="sortByOptionsHolder"
                                  id="sort_high_priced_first"
                                  onClick={() => {
                                    setSelectedOption((prev) => ({
                                      ...prev,
                                      name: "High-Priced First",
                                      value: "&order=desc&sort=selling_price",
                                    }));
                                    handleOptionChange(
                                      "&order=desc&sort=selling_price"
                                    );
                                  }}
                                >
                                  <p className="noMarginParagraph sortByOptions">
                                    High-Priced First
                                  </p>
                                </div>
                                <div
                                  className="sortByOptionsHolder"
                                  id="sort_low_priced_first"
                                  onClick={() => {
                                    setSelectedOption((prev) => ({
                                      ...prev,
                                      name: "Low-Priced First",
                                      value: "&order=asc&sort=selling_price",
                                    }));
                                    handleOptionChange(
                                      "&order=asc&sort=selling_price"
                                    );
                                  }}
                                >
                                  <p className="noMarginParagraph sortByOptions">
                                    Low-Priced First
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {products.length === 0 ? (
                        <div className="NoProductsshowMessage">
                          <div className="NoProductsAvailableImgContainer">
                            <img
                              src={NoProductsAvailable}
                              id="NoProductsAvailableImg"
                              alt="No Products Available"
                              className="image-container-list"
                              onError={(e) => {
                                e.target.src = placeHolderImage;
                              }}
                              loading="lazy"
                            />
                          </div>
                          <li className="listNameNoPro">
                            {" "}
                            No products available{" "}
                          </li>
                        </div>
                      ) : (
                        <InfiniteScroll
                          dataLength={products.length}
                          next={fetchNextUsers}
                          hasMore={scroll}
                          className="infinity-scrol-c1"
                        >
                          <>
                            {productsLoading ? (
                              <HomeSkeleton />
                            ) : (
                              <>
                                <div className="product-list">
                                  <>
                                    {products.map((product, index) => (
                                      <>
                                        <Card
                                          key={index}
                                          idParameret={index}
                                          item={product}
                                          fetchCartDetails={setFetchDataNow}
                                        />
                                      </>
                                    ))}
                                  </>
                                </div>
                                {scroll && (
                                  <div className="loading-more">
                                    Hold on, loading products...
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        </InfiniteScroll>
                      )}
                      {showGuestPopup && (
                        <ContinueAs handleCloseModal={handleCloseModal} />
                      )}
                    </div>
                  </>
                </div>
                <div id="product-list-end">
                  <div className="allFilterComponents">
                    <div
                      className={`mainFilterDivElement ${
                        products.length == 0 || isFilterSticky ? "sticky" : ""
                      }`}
                      id="filterBar"
                    >
                      {showFeatures.showSortBy && products.length > 1 && (
                        <div
                          className="SortButton"
                          style={{
                            width: showFeatures.showFilter ? "50%" : "100%",
                            borderRight: showFeatures.showFilter
                              ? "1px solid #cecece"
                              : "",
                          }}
                          onClick={toggleSortDrawer}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 7 9"
                            fill="red"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mobileUISortIcon"
                          >
                            <path
                              d="M1.75 6.3H0L2.625 9V0H1.75V6.3ZM4.375 1.35V9H5.25V2.7H7L4.375 0V1.35Z"
                              fill="#737373"
                            />
                          </svg>
                          <div>
                            <p className="sort-filter-text">Sort</p>
                            <p className="sort-filter-selected-text">
                              {DrawerSelectedSort.CreatedDate_Desc
                                ? "Newest First"
                                : DrawerSelectedSort.CreatedDate_Asc
                                ? "Oldest First"
                                : DrawerSelectedSort.Price_Desc
                                ? "High-Priced First"
                                : DrawerSelectedSort.Price_Asc
                                ? "Low-Priced First"
                                : ""}
                            </p>
                          </div>
                        </div>
                      )}

                      {showFeatures.showFilter && (
                        <div
                          style={{
                            width:
                              showFeatures.showSortBy && products.length > 1
                                ? "50%"
                                : "100%",
                          }}
                          className="FilterButton"
                          onClick={toggleFilterDrawer}
                        >
                          <svg
                            className="mobileUIFilterIcon"
                            width="20"
                            height="20"
                            viewBox="0 0 11 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.24993 1C3.56055 1 3.81236 1.25181 3.81236 1.56243V2.68729C3.81236 2.99792 3.56055 3.24973 3.24993 3.24973C2.93931 3.24973 2.6875 2.99792 2.6875 2.68729V1.56243C2.6875 1.25181 2.93931 1 3.24993 1Z"
                              stroke="#636363"
                              stroke-width="0.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.99924 2.12488H3.8125"
                              stroke="#636363"
                              stroke-width="0.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M2.68729 2.12488H1"
                              stroke="#636363"
                              stroke-width="0.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.24993 6.62451C3.56055 6.62451 3.81236 6.87632 3.81236 7.18694V8.31181C3.81236 8.62243 3.56055 8.87424 3.24993 8.87424C2.93931 8.87424 2.6875 8.62243 2.6875 8.31181V7.18694C2.6875 6.87632 2.93931 6.62451 3.24993 6.62451Z"
                              stroke="#636363"
                              stroke-width="0.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.99924 7.74939H3.8125"
                              stroke="#636363"
                              stroke-width="0.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M2.68729 7.74939H1"
                              stroke="#636363"
                              stroke-width="0.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.74993 3.81226C8.06055 3.81226 8.31236 4.06406 8.31236 4.37469V5.49955C8.31236 5.81017 8.06055 6.06198 7.74993 6.06198C7.43931 6.06198 7.1875 5.81017 7.1875 5.49955V4.37469C7.1875 4.06406 7.43931 3.81226 7.74993 3.81226Z"
                              stroke="#636363"
                              stroke-width="0.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.18674 4.93713H1"
                              stroke="#636363"
                              stroke-width="0.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.99979 4.93713H8.3125"
                              stroke="#636363"
                              stroke-width="0.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <div>
                            <p className="sort-filter-text">Filter</p>
                            <p className="sort-filter-selected-text">
                              {filterApplied
                                ? `${totalFilters} Applied`
                                : "No Filter"}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Sort Drawer */}
                    <Drawer
                      anchor="bottom"
                      open={isSortOpen}
                      onClose={toggleSortDrawer}
                    >
                      <div
                        className="filter-values-sort-item"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="sortBtnsHeader">
                          <p className="sortBtnsHeaderTxt">Sort</p>
                          <div
                            className="crossbuttonSort mobileViewOnly"
                            onClick={toggleSortDrawer}
                          >
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 12L11.3889 1"
                                stroke="#989898"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.3889 12L1 1"
                                stroke="#989898"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="sort-buttons">
                          <div
                            className="mobileUISortBtn"
                            onClick={() => {
                              if (isSortOpen) {
                                setSortOpen(!isSortOpen);
                              }
                              setSelectedOption((prev) => ({
                                ...prev,
                                name: "Newest First",
                                value: "&order=desc&sort=created_at",
                              }));
                              handleOptionChangemediaquery(
                                "&order=desc&sort=created_at"
                              );
                              setDrawerSelectedSort((prev) => ({
                                ...prev,
                                Name_Asc: false,
                                Name_Desc: false,
                                Price_Asc: false,
                                Price_Desc: false,
                                CreatedDate_Asc: false,
                                CreatedDate_Desc: true,
                              }));
                            }}
                            id={
                              DrawerSelectedSort.CreatedDate_Desc
                                ? "drawerSelectedBtn"
                                : ""
                            }
                          >
                            <div className="mobileUISortBtnIcon">
                              <svg
                                width="25"
                                height="16"
                                viewBox="0 0 23 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.6339 7.3125H14.6518C14.4897 7.3125 14.3571 7.17539 14.3571 7.00781V5.99219C14.3571 5.82461 14.4897 5.6875 14.6518 5.6875H15.6339C15.796 5.6875 15.9286 5.82461 15.9286 5.99219V7.00781C15.9286 7.17539 15.796 7.3125 15.6339 7.3125ZM18.2857 7.00781V5.99219C18.2857 5.82461 18.1531 5.6875 17.9911 5.6875H17.0089C16.8469 5.6875 16.7143 5.82461 16.7143 5.99219V7.00781C16.7143 7.17539 16.8469 7.3125 17.0089 7.3125H17.9911C18.1531 7.3125 18.2857 7.17539 18.2857 7.00781ZM20.6429 7.00781V5.99219C20.6429 5.82461 20.5103 5.6875 20.3482 5.6875H19.3661C19.204 5.6875 19.0714 5.82461 19.0714 5.99219V7.00781C19.0714 7.17539 19.204 7.3125 19.3661 7.3125H20.3482C20.5103 7.3125 20.6429 7.17539 20.6429 7.00781ZM18.2857 9.44531V8.42969C18.2857 8.26211 18.1531 8.125 17.9911 8.125H17.0089C16.8469 8.125 16.7143 8.26211 16.7143 8.42969V9.44531C16.7143 9.61289 16.8469 9.75 17.0089 9.75H17.9911C18.1531 9.75 18.2857 9.61289 18.2857 9.44531ZM15.9286 9.44531V8.42969C15.9286 8.26211 15.796 8.125 15.6339 8.125H14.6518C14.4897 8.125 14.3571 8.26211 14.3571 8.42969V9.44531C14.3571 9.61289 14.4897 9.75 14.6518 9.75H15.6339C15.796 9.75 15.9286 9.61289 15.9286 9.44531ZM20.6429 9.44531V8.42969C20.6429 8.26211 20.5103 8.125 20.3482 8.125H19.3661C19.204 8.125 19.0714 8.26211 19.0714 8.42969V9.44531C19.0714 9.61289 19.204 9.75 19.3661 9.75H20.3482C20.5103 9.75 20.6429 9.61289 20.6429 9.44531ZM23 2.84375V11.7812C23 12.4541 22.4721 13 21.8214 13H13.1786C12.5279 13 12 12.4541 12 11.7812V2.84375C12 2.1709 12.5279 1.625 13.1786 1.625H14.3571V0.304688C14.3571 0.137109 14.4897 0 14.6518 0H15.6339C15.796 0 15.9286 0.137109 15.9286 0.304688V1.625H19.0714V0.304688C19.0714 0.137109 19.204 0 19.3661 0H20.3482C20.5103 0 20.6429 0.137109 20.6429 0.304688V1.625H21.8214C22.4721 1.625 23 2.1709 23 2.84375ZM21.8214 11.6289V4.0625H13.1786V11.6289C13.1786 11.7127 13.2449 11.7812 13.3259 11.7812H21.6741C21.7551 11.7812 21.8214 11.7127 21.8214 11.6289Z"
                                  fill="#999999"
                                />
                                <path
                                  d="M1 5.66667L5 1L1 5.66667ZM5 1L9 5.66667L5 1ZM5 1L5 13L5 1Z"
                                  fill="#999999"
                                />
                                <path
                                  d="M1 5.66667L5 1M5 1L9 5.66667M5 1L5 13"
                                  stroke="#999999"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="mobileUISortBtnText">
                              {" "}
                              Newest First
                            </div>
                            <div className="mobileUISortBtnCheckbox">
                              <Radio
                                checked={DrawerSelectedSort.CreatedDate_Desc}
                                color="primary"
                                className={
                                  DrawerSelectedSort.CreatedDate_Desc
                                    ? "radio-primary checked"
                                    : "radio-primary"
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="mobileUISortBtn"
                            onClick={() => {
                              if (isSortOpen) {
                                setSortOpen(!isSortOpen);
                              }
                              setSelectedOption((prev) => ({
                                ...prev,
                                name: "Oldest First",
                                value: "&order=asc&sort=created_at",
                              }));
                              handleOptionChangemediaquery(
                                "&order=asc&sort=created_at"
                              );
                              setDrawerSelectedSort((prev) => ({
                                ...prev,
                                Name_Asc: false,
                                Name_Desc: false,
                                Price_Asc: false,
                                Price_Desc: false,
                                CreatedDate_Asc: true,
                                CreatedDate_Desc: false,
                              }));
                            }}
                            id={
                              DrawerSelectedSort.CreatedDate_Asc
                                ? "drawerSelectedBtn"
                                : ""
                            }
                          >
                            <div className="mobileUISortBtnIcon">
                              <svg
                                width="25"
                                height="16"
                                viewBox="0 0 23 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.6339 7.3125H14.6518C14.4897 7.3125 14.3571 7.17539 14.3571 7.00781V5.99219C14.3571 5.82461 14.4897 5.6875 14.6518 5.6875H15.6339C15.796 5.6875 15.9286 5.82461 15.9286 5.99219V7.00781C15.9286 7.17539 15.796 7.3125 15.6339 7.3125ZM18.2857 7.00781V5.99219C18.2857 5.82461 18.1531 5.6875 17.9911 5.6875H17.0089C16.8469 5.6875 16.7143 5.82461 16.7143 5.99219V7.00781C16.7143 7.17539 16.8469 7.3125 17.0089 7.3125H17.9911C18.1531 7.3125 18.2857 7.17539 18.2857 7.00781ZM20.6429 7.00781V5.99219C20.6429 5.82461 20.5103 5.6875 20.3482 5.6875H19.3661C19.204 5.6875 19.0714 5.82461 19.0714 5.99219V7.00781C19.0714 7.17539 19.204 7.3125 19.3661 7.3125H20.3482C20.5103 7.3125 20.6429 7.17539 20.6429 7.00781ZM18.2857 9.44531V8.42969C18.2857 8.26211 18.1531 8.125 17.9911 8.125H17.0089C16.8469 8.125 16.7143 8.26211 16.7143 8.42969V9.44531C16.7143 9.61289 16.8469 9.75 17.0089 9.75H17.9911C18.1531 9.75 18.2857 9.61289 18.2857 9.44531ZM15.9286 9.44531V8.42969C15.9286 8.26211 15.796 8.125 15.6339 8.125H14.6518C14.4897 8.125 14.3571 8.26211 14.3571 8.42969V9.44531C14.3571 9.61289 14.4897 9.75 14.6518 9.75H15.6339C15.796 9.75 15.9286 9.61289 15.9286 9.44531ZM20.6429 9.44531V8.42969C20.6429 8.26211 20.5103 8.125 20.3482 8.125H19.3661C19.204 8.125 19.0714 8.26211 19.0714 8.42969V9.44531C19.0714 9.61289 19.204 9.75 19.3661 9.75H20.3482C20.5103 9.75 20.6429 9.61289 20.6429 9.44531ZM23 2.84375V11.7812C23 12.4541 22.4721 13 21.8214 13H13.1786C12.5279 13 12 12.4541 12 11.7812V2.84375C12 2.1709 12.5279 1.625 13.1786 1.625H14.3571V0.304688C14.3571 0.137109 14.4897 0 14.6518 0H15.6339C15.796 0 15.9286 0.137109 15.9286 0.304688V1.625H19.0714V0.304688C19.0714 0.137109 19.204 0 19.3661 0H20.3482C20.5103 0 20.6429 0.137109 20.6429 0.304688V1.625H21.8214C22.4721 1.625 23 2.1709 23 2.84375ZM21.8214 11.6289V4.0625H13.1786V11.6289C13.1786 11.7127 13.2449 11.7812 13.3259 11.7812H21.6741C21.7551 11.7812 21.8214 11.7127 21.8214 11.6289Z"
                                  fill="#999999"
                                />
                                <path
                                  d="M9 8.33333L5 13L9 8.33333ZM5 13L1 8.33333L5 13ZM5 13V1V13Z"
                                  fill="#999999"
                                />
                                <path
                                  d="M9 8.33333L5 13M5 13L1 8.33333M5 13V1"
                                  stroke="#999999"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="mobileUISortBtnText">
                              {" "}
                              Oldest First
                            </div>
                            <div className="mobileUISortBtnCheckbox">
                              <Radio
                                checked={DrawerSelectedSort.CreatedDate_Asc}
                                color="primary"
                                className={
                                  DrawerSelectedSort.CreatedDate_Asc
                                    ? "radio-primary checked"
                                    : "radio-primary"
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="mobileUISortBtn"
                            onClick={() => {
                              if (isSortOpen) {
                                setSortOpen(!isSortOpen);
                              }
                              setSelectedOption((prev) => ({
                                ...prev,
                                name: "High-Priced First",
                                value: "&order=desc&sort=selling_price",
                              }));
                              handleOptionChangemediaquery(
                                "&order=desc&sort=selling_price"
                              );
                              setDrawerSelectedSort((prev) => ({
                                ...prev,
                                Name_Asc: false,
                                Name_Desc: false,
                                Price_Asc: false,
                                Price_Desc: true,
                                CreatedDate_Asc: false,
                                CreatedDate_Desc: false,
                              }));
                            }}
                            id={
                              DrawerSelectedSort.Price_Desc
                                ? "drawerSelectedBtn"
                                : ""
                            }
                          >
                            <div className="mobileUISortBtnIcon">
                              <svg
                                width="25"
                                height="16"
                                viewBox="0 0 24 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14 1.87368V1H23V1.87368H19.178C19.9124 2.50828 20.4136 3.43844 20.523 4.49472H23V5.3684H20.523C20.3195 7.33416 18.7588 8.86311 16.8636 8.86311H15.515L20.4026 13.3367L19.8701 14L14.1429 8.75795C14.0129 8.63898 13.966 8.44617 14.0254 8.27472C14.0848 8.10327 14.2379 7.98944 14.4091 7.98944H16.8636C18.3063 7.98944 19.4998 6.85029 19.6983 5.3684H14V4.49472H19.6983C19.4998 3.01283 18.3063 1.87368 16.8636 1.87368H14Z"
                                  fill="#999999"
                                  stroke="#999999"
                                  stroke-width="0.5"
                                />
                                <path
                                  d="M1 5.66667L5 1L1 5.66667ZM5 1L9 5.66667L5 1ZM5 1L5 13L5 1Z"
                                  fill="#999999"
                                />
                                <path
                                  d="M1 5.66667L5 1M5 1L9 5.66667M5 1L5 13"
                                  stroke="#999999"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="mobileUISortBtnText">
                              {" "}
                              High-Priced First
                            </div>
                            <div className="mobileUISortBtnCheckbox">
                              <Radio
                                checked={DrawerSelectedSort.Price_Desc}
                                color="primary"
                                className={
                                  DrawerSelectedSort.Price_Desc
                                    ? "radio-primary checked"
                                    : "radio-primary"
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="mobileUISortBtn"
                            onClick={() => {
                              if (isSortOpen) {
                                setSortOpen(!isSortOpen);
                              }
                              setSelectedOption((prev) => ({
                                ...prev,
                                name: "Low-Priced First",
                                value: "&order=asc&sort=selling_price",
                              }));
                              handleOptionChangemediaquery(
                                "&order=asc&sort=selling_price"
                              );
                              setDrawerSelectedSort((prev) => ({
                                ...prev,
                                Name_Asc: false,
                                Name_Desc: false,
                                Price_Asc: true,
                                Price_Desc: false,
                                CreatedDate_Asc: false,
                                CreatedDate_Desc: false,
                              }));
                            }}
                            id={
                              DrawerSelectedSort.Price_Asc
                                ? "drawerSelectedBtn"
                                : ""
                            }
                          >
                            <div className="mobileUISortBtnIcon">
                              <svg
                                width="25"
                                height="16"
                                viewBox="0 0 24 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14 1.87368V1H23V1.87368H19.178C19.9124 2.50828 20.4136 3.43844 20.523 4.49472H23V5.3684H20.523C20.3195 7.33416 18.7588 8.86311 16.8636 8.86311H15.515L20.4026 13.3367L19.8701 14L14.1429 8.75795C14.0129 8.63898 13.966 8.44617 14.0254 8.27472C14.0848 8.10327 14.2379 7.98944 14.4091 7.98944H16.8636C18.3063 7.98944 19.4998 6.85029 19.6983 5.3684H14V4.49472H19.6983C19.4998 3.01283 18.3063 1.87368 16.8636 1.87368H14Z"
                                  fill="#999999"
                                  stroke="#999999"
                                  stroke-width="0.5"
                                />
                                <path
                                  d="M9 8.33333L5 13L9 8.33333ZM5 13L1 8.33333L5 13ZM5 13V1V13Z"
                                  fill="#999999"
                                />
                                <path
                                  d="M9 8.33333L5 13M5 13L1 8.33333M5 13V1"
                                  stroke="#999999"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="mobileUISortBtnText">
                              {" "}
                              Low-Priced First
                            </div>
                            <div className="mobileUISortBtnCheckbox">
                              <Radio
                                checked={DrawerSelectedSort.Price_Asc}
                                color="primary"
                                className={
                                  DrawerSelectedSort.Price_Asc
                                    ? "radio-primary checked"
                                    : "radio-primary"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Drawer>

                    {/* Filter Drawer */}
                    <Drawer
                      className="filterDrawer"
                      anchor="left"
                      open={isFilterOpen && showFeatures.showFilter}
                      onClose={toggleFilterDrawer}
                    >
                      <div className="mobileViewOnly loginMobilePageNavbar">
                        <div
                          className="navbar__logo"
                          onClick={() => {
                            sessionStorage.removeItem("multiFilters");
                            navigate(`${navigation.navigateToHomepage}`);
                            window.location.reload();
                          }}
                        >
                          <h3 className="orgName">{`${ORG_NAME}`} </h3>
                        </div>
                        <div
                          className="crossbuttonNavbar mobileViewOnly"
                          onClick={toggleFilterDrawerClose}
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 12L11.3889 1"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3889 12L1 1"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="filterHeaderContainer">
                        <div className="">
                          <p className="mobileUIFilterHeaderTxt">FILTERS</p>
                        </div>
                        <div
                          className={`clearFilterBtnContainer-c1 ${
                            filterApplied ? "fade-in" : "fade-out"
                          }`}
                          onClick={() => {
                            if (filterApplied) {
                              clearFilter();
                            }
                          }}
                        >
                          <p
                            className="mobileUIFilterHeaderTxt"
                            id="clearAllFilterMobileUI"
                          >
                            CLEAR ALL
                          </p>
                        </div>
                      </div>
                      <div className="FilterContainerMediaQuery">
                        <div className="filterContainerMobileUI">
                          <div className="filterNameHolderMobileUI">
                            {showFeatures.showPriceFilter &&
                              priceData.length > 0 && (
                                <div
                                  className={
                                    filterExpanded.price
                                      ? "filter-container selectedFilterContainerMobileUI"
                                      : "filter-container "
                                  }
                                  onClick={() => {
                                    setFilterExpanded((prev) => ({
                                      ...prev,
                                      brand: false,
                                      category: false,
                                      age: false,
                                      condition: false,
                                      price: true,
                                      sort: false,
                                      availability: false,
                                    }));
                                  }}
                                >
                                  <div
                                    className={
                                      filterExpanded.price
                                        ? "filter selectedFilterMobileUI"
                                        : "filter"
                                    }
                                  >
                                    <div className={"filter-header "}>
                                      <h3
                                        className="allsortname"
                                        id="priceFilterHeader"
                                      >
                                        Price
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              )}

                            {showFeatures.showAgeFilter &&
                              ageData.length > 0 && (
                                <div
                                  className={
                                    filterExpanded.age
                                      ? "filter-container selectedFilterContainerMobileUI"
                                      : "filter-container "
                                  }
                                  onClick={() => {
                                    setFilterExpanded((prev) => ({
                                      ...prev,
                                      brand: false,
                                      category: false,
                                      age: true,
                                      condition: false,
                                      price: false,
                                      sort: false,
                                      availability: false,
                                    }));
                                  }}
                                >
                                  <div
                                    className={
                                      filterExpanded.age
                                        ? "filter selectedFilterMobileUI"
                                        : "filter"
                                    }
                                  >
                                    <div className={"filter-header "}>
                                      <h3
                                        className="allsortname"
                                        id="ageFilterHeader"
                                      >
                                        Age Group
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              )}

                            {showFeatures.showConditionFilter &&
                              conditionData.length > 0 && (
                                <div
                                  className={
                                    filterExpanded.condition
                                      ? "filter-container selectedFilterContainerMobileUI"
                                      : "filter-container "
                                  }
                                  onClick={() => {
                                    setFilterExpanded((prev) => ({
                                      ...prev,
                                      brand: false,
                                      category: false,
                                      age: false,
                                      condition: true,
                                      price: false,
                                      sort: false,
                                      availability: false,
                                    }));
                                  }}
                                >
                                  <div
                                    className={
                                      filterExpanded.condition
                                        ? "filter selectedFilterMobileUI"
                                        : "filter"
                                    }
                                  >
                                    <div className={"filter-header "}>
                                      <h3
                                        className="allsortname"
                                        id="conditionFilterHeader"
                                      >
                                        Conditions
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              )}

                            {showFeatures.showBrandFilter &&
                              brandData.length > 0 && (
                                <div
                                  className={
                                    filterExpanded.brand
                                      ? "filter-container selectedFilterContainerMobileUI"
                                      : "filter-container "
                                  }
                                  onClick={() => {
                                    setFilterExpanded((prev) => ({
                                      ...prev,
                                      brand: true,
                                      category: false,
                                      age: false,
                                      condition: false,
                                      price: false,
                                      sort: false,
                                      availability: false,
                                    }));
                                  }}
                                >
                                  <div
                                    className={
                                      filterExpanded.brand
                                        ? "filter selectedFilterMobileUI"
                                        : "filter"
                                    }
                                  >
                                    <div className="filter-header ">
                                      <h3
                                        className="allsortname"
                                        id="brandFilterHeader"
                                      >
                                        Brands
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>

                          <div className="filterOptionsHolderMobileUI">
                            {showFeatures.showBrandFilter &&
                              filterExpanded.brand && (
                                <>
                                  <div className="FilterSearchbarContainerHolder">
                                    <div className="FilterSearchbarContainer">
                                      <div className="filterSearchbarInputContainer">
                                        <input
                                          type="text"
                                          autoFocus
                                          placeholder="Search Brands"
                                          className="filterBrandsName"
                                          value={brandSearch.inputValue}
                                          onChange={(e) => {
                                            let str =
                                              e.target.value.toLowerCase();
                                            if (
                                              str.length > 2 ||
                                              str.length == 0
                                            ) {
                                              const filteredOptions =
                                                filterArray(
                                                  brandData,
                                                  "name",
                                                  str
                                                );
                                              setBrandSearch((prev) => ({
                                                ...prev,
                                                inputValue: str,
                                                brandsList: filteredOptions,
                                              }));
                                              if (filteredOptions.length == 0) {
                                                setNoProductsForFilter(
                                                  (prev) => ({
                                                    ...prev,
                                                    brandsList: true,
                                                  })
                                                );
                                              } else {
                                                setNoProductsForFilter(
                                                  (prev) => ({
                                                    ...prev,
                                                    brandsList: false,
                                                  })
                                                );
                                              }
                                            } else {
                                              setBrandSearch((prev) => ({
                                                ...prev,
                                                inputValue: str,
                                              }));
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="crossbuttonBrandsFilter">
                                        <FontAwesomeIcon
                                          icon={faXmark}
                                          className="fa-x-mark-icons"
                                          onClick={() => {
                                            setNoProductsForFilter((prev) => ({
                                              ...prev,
                                              brandsList: false,
                                            }));
                                            setBrandSearch((prev) => ({
                                              ...prev,
                                              inputBoxEnable: false,
                                              inputValue: "",
                                              brandsList: brandData,
                                            }));
                                            setShowAllBrandList(false);
                                          }}
                                          tabindex="0"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="filter-valuesMediaQuery"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    {brandSearch.brandsList && (
                                      <div>
                                        {brandSearch.brandsList.map(
                                          (brand, index) => (
                                            <div
                                              key={brand.id}
                                              className="filter-product-age"
                                            >
                                              <label
                                                className="filterCheckboxLabel"
                                                id={`brandFilter_${index}`}
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    brand.checked =
                                                      !brand.checked;
                                                    if (brand.checked) {
                                                      pushToArray(
                                                        "brand_id",
                                                        brand.id
                                                      );
                                                      setCheckedUncheckedForBrandData();
                                                    } else {
                                                      removeFromArray(
                                                        "brand_id",
                                                        brand.id
                                                      );
                                                      setCheckedUncheckedForBrandData();
                                                    }
                                                  }
                                                }}
                                              >
                                                <div
                                                  className={
                                                    brand.checked
                                                      ? "filterCheckboxBox checked"
                                                      : "filterCheckboxBox"
                                                  }
                                                >
                                                  {brand.checked && (
                                                    <FontAwesomeIcon
                                                      icon={faCheck}
                                                      className="fa-check-icons-product"
                                                    />
                                                  )}
                                                </div>
                                                <input
                                                  id={`brand_id_${brand.id}`}
                                                  type="checkbox"
                                                  // tabIndex={-1}
                                                  className="input-checkobox-op"
                                                  checked={brand.checked}
                                                  onChange={async (e) => {
                                                    if (e.target.checked) {
                                                      pushToArray(
                                                        "brand_id",
                                                        brand.id
                                                      );
                                                      setCheckedUncheckedForBrandData();
                                                    } else {
                                                      removeFromArray(
                                                        "brand_id",
                                                        brand.id
                                                      );
                                                      setCheckedUncheckedForBrandData();
                                                    }
                                                  }}
                                                />

                                                {brand.name}
                                              </label>
                                            </div>
                                          )
                                        )}
                                        {noProductsForFilter.brandsList && (
                                          <p>No Brands Found</p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}

                            {showFeatures.showAgeFilter &&
                              filterExpanded.age && (
                                <div
                                  className="filter-valuesMediaQuery"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <div>
                                    {ageData.map((age, index) => (
                                      <div
                                        key={index}
                                        className="filter-product-age"
                                      >
                                        <label
                                          className="filterCheckboxLabel"
                                          id={`ageFilter_${index}`}
                                        >
                                          <div
                                            className={
                                              age.checked
                                                ? "filterCheckboxBox checked"
                                                : "filterCheckboxBox"
                                            }
                                          >
                                            {age.checked && (
                                              <FontAwesomeIcon
                                                icon={faCheck}
                                                className="fa-check-icons-product"
                                              />
                                            )}
                                          </div>
                                          <input
                                            id={`age_${age.title}`}
                                            type="checkbox"
                                            value={age.value}
                                            className="input-checkobox-op"
                                            checked={age.checked}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                pushToArray(
                                                  "age_group",
                                                  e.target.value
                                                );
                                                setCheckedUncheckedForAge();
                                              } else {
                                                removeFromArray(
                                                  "age_group",
                                                  e.target.value
                                                );
                                                setCheckedUncheckedForAge();
                                              }
                                            }}
                                          />

                                          {age.title}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}

                            {showFeatures.showConditionFilter &&
                              filterExpanded.condition && (
                                <div
                                  className="filter-valuesMediaQuery"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <div>
                                    {conditionData.map((condition, index) => (
                                      <div
                                        key={index}
                                        className="filter-product-age"
                                      >
                                        <label
                                          className="filterCheckboxLabel"
                                          id={`conditionFilter_${index}`}
                                        >
                                          <div
                                            className={
                                              condition.checked
                                                ? "filterCheckboxBox checked"
                                                : "filterCheckboxBox"
                                            }
                                          >
                                            {condition.checked && (
                                              <FontAwesomeIcon
                                                icon={faCheck}
                                                className="fa-check-icons-product"
                                              />
                                            )}
                                          </div>
                                          <input
                                            id={`condition_${condition.name
                                              .split(" ")
                                              .join("")}`}
                                            type="checkbox"
                                            value={condition.label}
                                            className="input-checkobox-op"
                                            checked={condition.checked}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                pushToArray(
                                                  "condition",
                                                  e.target.value
                                                );
                                                setCheckedUncheckedForCondition();
                                              } else {
                                                removeFromArray(
                                                  "condition",
                                                  e.target.value
                                                );
                                                setCheckedUncheckedForCondition();
                                              }
                                            }}
                                          />

                                          {condition.label}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}

                            {showFeatures.showPriceFilter &&
                              filterExpanded.price && (
                                <div
                                  className="filter-valuesMediaQuery"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <div>
                                    {priceData.map((price, index) => (
                                      <div
                                        key={index}
                                        className="filter-product-age"
                                      >
                                        <label
                                          className="filterCheckboxLabel"
                                          id={`priceFilter_${index}`}
                                        >
                                          <div
                                            className={
                                              price.checked
                                                ? "filterCheckboxBox checked"
                                                : "filterCheckboxBox"
                                            }
                                          >
                                            {price.checked && (
                                              <FontAwesomeIcon
                                                icon={faCheck}
                                                className="fa-check-icons-product"
                                              />
                                            )}
                                          </div>
                                          <input
                                            id={`price_${price.name
                                              .split(" ")
                                              .join("")}`}
                                            type="checkbox"
                                            value={price.name}
                                            className="input-checkobox-op"
                                            checked={price.checked}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                pushToArray(
                                                  "price_range",
                                                  e.target.value
                                                );
                                                setCheckedUncheckedForPriceData();
                                              } else {
                                                removeFromArray(
                                                  "price_range",
                                                  e.target.value
                                                );
                                                setCheckedUncheckedForPriceData();
                                              }
                                            }}
                                          />

                                          {price.label}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="buttondiv">
                        <button
                          className="Applyfilterdiv"
                          onClick={() => {
                            toggleFilterDrawerClose();
                            setBrandSearch((prev) => ({
                              ...prev,
                              ...prev,
                              inputBoxEnable: false,
                              inputValue: "",
                              brandsList: brandData,
                            }));
                          }}
                        >
                          DONE
                        </button>
                      </div>
                    </Drawer>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ProductList;
