import React, { useRef, useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import ParamsContext from "../useContext/paramContext";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import "./home.css";
import Endpoints from "../../config/APIEndpoints.json";
import LazyImage from "../LazyImage/LazyImage";
import SkeletonComponent from "../skeleton/Skeleton";
const API_HOST = process.env.REACT_APP_API_HOST;
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} banner-left-arrow`}
      style={{
        ...style,
        background: "#00000063",
        height: "70px",
        width: "30px",
        borderRadius: "0px 5px 5px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: 0,
        marginTop: "-5px",
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} banner-right-arrow`}
      style={{
        ...style,
        background: "#00000063",
        height: "70px",
        width: "30px",
        borderRadius: "5px 0px 0px 5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: 0,
        marginTop: "-5px",
      }}
      onClick={onClick}
    />
  );
}

const BannerSlider = (props) => {
  const navigate = useNavigate();
  let [loadingImg, setLoadingImg] = useState(true);
  const contextObj = useContext(ParamsContext);
  const [webMode, setwebMode] = useState(window.innerWidth > 600);
  const bannerRef = useRef(null);
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    if (props.setBannerLoaded !== undefined && props.setBannerLoaded !== null) {
      props.setBannerLoaded(loadingImg);
    }
  }, [loadingImg]);

  const getAllBanners = async () => {
    try {
      const response = await fetch(`${API_HOST}/${Endpoints.getBanners}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();
      if (
        res.status >= 500 ||
        (res.message && res.message.toLowerCase().includes("server error"))
      ) {
        contextObj.setInput("serviceUnavailable", true);
      }
      if (res.data) {
        setBanners(res.data);
        // console.log("banner res ====>", res.data);
      } else {
        setLoadingImg(false);
      }
      // console.log("setting banner loading true");
    } catch (error) {
      setLoadingImg(false);
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setwebMode(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getAllBanners();
  }, []);
  const handleImageClick = (banner) => {
    if (!isDragging && banner.cta_url) {
      window.open(banner.cta_url, "_blank");
    }
  };

  const settings = {
    dots: true,
    lazyLoad: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  return (
    <div ref={bannerRef} className="bannerSliderContainer">
      {loadingImg && banners.length == 0 && (
        <SkeletonComponent
          className={
            webMode
              ? "web-banner bannerSkeleton"
              : "mobile-banner bannerSkeleton"
          }
        />
      )}

      {banners && banners.length > 0 && (
        <div className="slider ">
          <Slider ref={sliderRef} {...settings}>
            {banners.map((banner, index) => (
              <div key={index} onClick={() => handleImageClick(banner)}>
                {webMode ? (
                  <LazyImage
                    src={`${imgHost}/${banner.image1}`}
                    style={{
                      cursor: banner.cta_url !== null ? "pointer" : "default",
                    }}
                    className="web-banner"
                    loadingImg={setLoadingImg}
                  />
                ) : (
                  <LazyImage
                    src={`${imgHost}/${banner.image2}`}
                    alt="image"
                    style={{
                      cursor: banner.cta_url !== null ? "pointer" : "default",
                    }}
                    className="mobile-banner"
                    loadingImg={setLoadingImg}
                  />
                )}
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default BannerSlider;
