import React, { useEffect, useState, useContext, useRef } from "react";
import ParamsContext from "../useContext/paramContext";
import { useNavigate } from "react-router-dom";
import CategoryDropDown from "./categoryDropDown";
import { getAllCategoriesData } from "../../resuableFunctions/resuableFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";

const NavbarCategorySwiper = (props) => {
  const [showFeatures, setShowFeatures] = useState({
    showCategoryFilter: true,
  });

  const lastItemRef = useRef(null);
  const scrollAmount = 150;

  function smoothScroll(element, start, end) {
    let startTime = null;
    const duration = 300;

    function step(timestamp) {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const scrollDistance = end - start;
      const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
      const scrollAmount =
        scrollDistance * easeInOutQuad(Math.min(progress / duration, 1));
      element.scrollLeft = start + scrollAmount;
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }
  const [scrollIntervalLeft, setScrollIntervalLeft] = useState(null);
  const handleScrollLeft = () => {
    const container = document.getElementById("topRibbonCategoryHolder");
    if (container) {
      if (container.scrollLeft < scrollAmount) {
        smoothScroll(container, container.scrollLeft, 0);
        setActiveScrollButton((prev) => ({
          ...prev,
          left: false,
        }));
        const button = document.getElementById("category-scroll-arrow-left");

        // Check if the button exists
        if (button) {
          // Create a new mouseup event
          const mouseUpEvent = new MouseEvent("mouseup", {
            bubbles: true,
            cancelable: true,
            view: window,
          });

          // Dispatch the mouseup event on the button
          button.dispatchEvent(mouseUpEvent);
        }

        return;
      } else {
        const targetScrollLeft = Math.max(
          container.scrollLeft - scrollAmount,
          0
        );
        if (targetScrollLeft >= 0) {
          smoothScroll(container, container.scrollLeft, targetScrollLeft);
          setActiveScrollButton((prev) => ({
            ...prev,
            left: true,
            right: true,
          }));
        } else {
          stopScrollingLeft();
          setActiveScrollButton((prev) => ({
            ...prev,
            left: false,
          }));
        }
      }
    } else {
      stopScrollingLeft(); // Clear interval if container not found
    }
  };

  const startScrollingLeft = () => {
    if (scrollIntervalLeft) return; // Return if already scrolling
    const intervalLeft = setInterval(() => {
      handleScrollLeft();
    }, 300); // Adjust the interval as needed
    setScrollIntervalLeft(intervalLeft);
  };

  // Function to stop scrolling when mouse up or mouse leaves
  const stopScrollingLeft = () => {
    clearInterval(scrollIntervalLeft);
    setScrollIntervalLeft(null);
  };

  // right
  const [scrollIntervalRight, setScrollIntervalRight] = useState(null);
  // Function to start scrolling repeatedly
  const startScrollingRight = () => {
    // if (scrollIntervalRight) {
    const intervalRight = setInterval(() => {
      handleScrollRight();
    }, 300); // Adjust the interval as needed
    setScrollIntervalRight(intervalRight);
    // }
  };
  // Function to stop scrolling when mouse up or leaves
  const stopScrollingRight = () => {
    clearInterval(scrollIntervalRight);
    setScrollIntervalRight(null);
  };

  let [dropDownPosition, setDropDownPosition] = useState("0px");
  let [activeScrollButton, setActiveScrollButton] = useState({
    left: false,
    right: true,
  });

  const handleScrollRight = (e) => {
    const container = document.getElementById("topRibbonCategoryHolder");
    const scrollableWidth = container.scrollWidth - container.clientWidth;
    const targetScrollRight = Math.min(
      container.scrollLeft + scrollAmount,
      scrollableWidth
    );

    if (
      scrollableWidth - targetScrollRight < scrollAmount ||
      targetScrollRight == scrollableWidth
    ) {
      stopScrollingRight();
      smoothScroll(container, container.scrollLeft, scrollableWidth);
      setActiveScrollButton((prev) => ({
        ...prev,
        right: false,
      }));
      const button = document.getElementById("category-scroll-arrow-right");

      if (button) {
        // Create a new mouseup event
        const mouseUpEvent = new MouseEvent("mouseup", {
          bubbles: true,
          cancelable: true,
          view: window,
        });

        button.dispatchEvent(mouseUpEvent);
      }
      return;
    }

    if (targetScrollRight <= scrollableWidth) {
      smoothScroll(container, container.scrollLeft, targetScrollRight);

      if (container.scrollLeft < scrollableWidth) {
        setActiveScrollButton((prev) => ({
          ...prev,
          right: true,
        }));
      } else {
        setActiveScrollButton((prev) => ({
          ...prev,
          right: false,
        }));
      }

      setActiveScrollButton((prev) => ({
        ...prev,
        left: true,
      }));
    }
  };

  let filterParamsUpdated = [
    {
      columnName: "brand_id",
      values: [],
    },
    {
      columnName: "category_id",
      values: [],
    },
    {
      columnName: "age_group",
      values: [],
    },
    {
      columnName: "condition",
      values: [],
    },
    {
      columnName: "price_range",
      values: [],
    },
    {
      columnName: "sort",
      values: [],
    },
  ];
  const navigate = useNavigate();
  const navigateToCategory = async (category) => {
    sessionStorage.setItem("selectedCategoryId", category.id);
    const session_filterParamsUpdated = sessionStorage.getItem(
      "filterParamsUpdated"
    );
    if (session_filterParamsUpdated !== null) {
      filterParamsUpdated = await JSON.parse(session_filterParamsUpdated);
    }
    for (let i = 0; i < filterParamsUpdated.length; i++) {
      if (filterParamsUpdated[i].columnName == "category_id") {
        filterParamsUpdated[i].values == [];
        filterParamsUpdated[i].values = [category.id];
        break;
      }
    }
    sessionStorage.setItem(
      "filterParamsUpdated",
      JSON.stringify(filterParamsUpdated)
    );
    await contextObj.setInput("categoryFilterApplied", true);
    navigate("/products");
  };

  const navigateToAllProducts = async () => {
    window.scrollTo(0, 0);
    setSelectedCategoryId("allProducts");
    await contextObj.setInput("reSetFilters", true);
    navigate("/products");
  };
  const [items, setItems] = useState([]);
  const reFetchCategoriesData = async () => {
    let serviceUnavailable = await getAllCategoriesData();
    if (serviceUnavailable) {
      contextObj.setInput("serviceUnavailable", true);
    }

    let categoryInCookies = sessionStorage.getItem("parentCategoryListData");
    categoryInCookies = JSON.parse(categoryInCookies);
    if (categoryInCookies && categoryInCookies.data) {
      categoryInCookies = categoryInCookies.data;
      setItems(categoryInCookies);
    }
  };
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const contextObj = useContext(ParamsContext);
  const updateSelectedCategory = async () => {
    let selectedCategoryId = sessionStorage.getItem("selectedCategoryId");

    if (selectedCategoryId) {
      setSelectedCategoryId(selectedCategoryId);
    } else {
      setSelectedCategoryId("allProducts");
    }
  };
  useEffect(() => {
    let featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showCategoryFilter: featureFlags.buyer_products_categories,
      }));
    }
  }, [contextObj.params.featureFlags]);
  useEffect(() => {
    if (items) {
      updateSelectedCategory();
    } else {
      setSelectedCategoryId("");
    }
  }, [, contextObj.params.resetSelectedCategoryId]);

  useEffect(() => {
    let categoryInSession = sessionStorage.getItem("parentCategoryListData");
    if (categoryInSession === null || categoryInSession === undefined) {
      reFetchCategoriesData();
    } else {
      categoryInSession = JSON.parse(categoryInSession);
      categoryInSession = categoryInSession.data;
      if (categoryInSession) {
        setItems(categoryInSession);
      }
    }
  }, []);

  const navigateToParentCategory = async (categories) => {
    const session_filterParamsUpdated = sessionStorage.getItem(
      "filterParamsUpdated"
    );
    if (session_filterParamsUpdated !== null) {
      filterParamsUpdated = await JSON.parse(session_filterParamsUpdated);
    }
    let categoryArray = [];

    categories.forEach((category) => {
      categoryArray.push(category.id);
    });

    for (let i = 0; i < filterParamsUpdated.length; i++) {
      if (filterParamsUpdated[i].columnName == "category_id") {
        filterParamsUpdated[i].values == [];
        filterParamsUpdated[i].values = categoryArray;
        break;
      }
    }
    sessionStorage.setItem(
      "filterParamsUpdated",
      JSON.stringify(filterParamsUpdated)
    );
    await contextObj.setInput("categoryFilterApplied", true);
    navigate("/products");
  };
  const container = document.getElementById("topRibbonCategoryHolder");

  useEffect(() => {
    const handleResize = () => {
      setActiveScrollButton((prev) => ({
        ...prev,
        left: container.scrollLeft == 0 ? false : true,
        right: container.scrollWidth > container.clientWidth,
      }));
    };
    if (container !== null) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [container]);

  useEffect(() => {
    const container = document.getElementById("topRibbonCategoryHolder");
    if (container) {
      setActiveScrollButton((prev) => ({
        ...prev,
        right: container.scrollWidth > container.clientWidth,
      }));
    }
  }, [lastItemRef.current]);

  const closeCategoryDropDown = (itemId) => {
    setDropDownPosition("0px"),
      setItems((prevItems) => {
        return prevItems.map((prevItem) => {
          if (prevItem.id === itemId) {
            return { ...prevItem, showList: false };
          } else {
            return prevItem;
          }
        });
      });
  };

  return (
    <div className="top-menu-bar">
      <ul
        style={{
          margin: "0px",
          padding: "0px",
          listStyle: "none",
        }}
      >
        <li
          role="button"
          tabIndex="0"
          onClick={() => {
            navigateToAllProducts();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              // Trigger action on Enter or Space key press
              navigateToAllProducts();
            }
          }}
          onMouseDown={(e) => {
            if (e.button == 1) {
              e.preventDefault();
              window.scrollTo(0, 0);
              navigateToAllProducts();
            }
          }}
          id="navbar_all_Products"
          className="all-prdoducts-menu topRibbonCategoryName"
        >
          <p className="noMarginParagraph"> All Products </p>
        </li>
      </ul>

      {showFeatures.showCategoryFilter && (
        <>
          {/* Left Arrow */}
          {activeScrollButton.left && (
            <div
              className="category-scroll-arrow ribbon-left-arrow"
              id="category-scroll-arrow-left"
              onMouseDown={startScrollingLeft}
              onMouseUp={stopScrollingLeft}
              onMouseLeave={stopScrollingLeft}
              onClick={handleScrollLeft}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          )}
          {/* Category Menu List */}
          <div id="topRibbonCategoryHolder">
            {items &&
              items.map((item, index) => (
                <div
                  key={index + 1}
                  className="topRibbonCategoryList navbar-logocontainer-button"
                >
                  <ul
                    style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                  >
                    <li
                      ref={index === items.length - 1 ? lastItemRef : null}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setSelectedCategoryId(item.id);
                        if (
                          item.childCategory &&
                          item.childCategory.length > 0
                        ) {
                          sessionStorage.setItem("selectedCategoryId", item.id);
                          navigateToParentCategory(item.childCategory);
                        } else {
                          navigateToCategory(item);
                        }
                      }}
                      onMouseDown={(e) => {
                        if (e.button === 1) {
                          e.preventDefault();
                          window.scrollTo(0, 0);
                          setSelectedCategoryId(item.id);
                          if (
                            item.childCategory &&
                            item.childCategory.length > 0
                          ) {
                            sessionStorage.setItem(
                              "selectedCategoryId",
                              item.id
                            );
                            navigateToParentCategory(item.childCategory);
                          } else {
                            navigateToCategory(item);
                          }
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          // Trigger action on Enter or Space key press
                          window.scrollTo(0, 0);
                          setSelectedCategoryId(item.id);
                          if (
                            item.childCategory &&
                            item.childCategory.length > 0
                          ) {
                            sessionStorage.setItem(
                              "selectedCategoryId",
                              item.id
                            );
                            navigateToParentCategory(item.childCategory);
                          } else {
                            navigateToCategory(item);
                          }
                        }
                      }}
                      className="topRibbonCategoryName"
                      tabIndex={0} // Add tabindex here
                    >
                      <p className="noMarginParagraph">{item.name}</p>

                      {item.showList &&
                        item.childCategory &&
                        item.childCategory.length > 0 && (
                          <div
                            className="CategoryDropDownContainer"
                            style={{
                              left: `${dropDownPosition}`,
                              position: "absolute",
                            }}
                          >
                            <CategoryDropDown
                              setSelectedCategoryId={setSelectedCategoryId}
                              childCategories={item.childCategory}
                              show={item.showList}
                              closeCategoryDropDown={closeCategoryDropDown}
                              item={item}
                            />
                          </div>
                        )}
                    </li>
                  </ul>
                </div>
              ))}
          </div>
          {/* Right arrow */}
          {activeScrollButton.right && (
            <div
              className="category-scroll-arrow ribbon-right-arrow"
              id="category-scroll-arrow-right"
              onMouseDown={startScrollingRight}
              onMouseUp={stopScrollingRight}
              onMouseLeave={stopScrollingRight}
              onClick={handleScrollRight}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NavbarCategorySwiper;
