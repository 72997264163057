import React, { useEffect, useState, useRef, useContext } from "react";
import ParamsContext from "../useContext/paramContext";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, FreeMode } from "swiper/modules";
import Navigate from "../../config/Navigation.json";
import { getAllCategoriesData } from "../../resuableFunctions/resuableFunctions";
import "swiper/css";
import "swiper/css/pagination";
import "./home.css";
import SkeletonComponent from "../skeleton/Skeleton";
import ShopByCategoryElement from "./shopByCategoryElement";
const ShopByCategory = (props) => {
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    buyer_homepage_categoryview: true,
  });
  useEffect(() => {
    let featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showCategoryFilter: featureFlags.buyer_products_categories,
      }));
    }
  }, [contextObj.params.featureFlags]);
  const ShopByCategoryRef = useRef(null);
  function pushToArray(columnName, value, parentCategoryId) {
    sessionStorage.removeItem("filterParamsUpdated");
    sessionStorage.removeItem("selectedCategoryId");

    let filterParamsUpdated = [
      {
        columnName: "brand_id",
        values: [],
      },
      {
        columnName: "category_id",
        values: [],
      },
      {
        columnName: "age_group",
        values: [],
      },
      {
        columnName: "condition",
        values: [],
      },
      {
        columnName: "price_range",
        values: [],
      },
      {
        columnName: "stock",
        values: [],
      },
      {
        columnName: "sort",
        values: [],
      },
    ];

    for (let i = 0; i < filterParamsUpdated.length; i++) {
      if (
        filterParamsUpdated[i].columnName === columnName &&
        !filterParamsUpdated[i].values.includes(value)
      ) {
        filterParamsUpdated[i].values.push(`${value}`);
        break;
      }
    }
    sessionStorage.setItem("selectedCategoryId", parentCategoryId);

    sessionStorage.setItem(
      "filterParamsUpdated",
      JSON.stringify(filterParamsUpdated)
    );
    window.open(`${Navigate.navigateToProductList}`, "_blank");
  }
  const [categoriesData, setCategoriesData] = useState([]);
  const fetchAllCategoriesData = async () => {
    let categoryInSession = sessionStorage.getItem("categoryListData");
    if (categoryInSession == null || categoryInSession == undefined) {
      let serviceUnavailable = await getAllCategoriesData();
      if (serviceUnavailable) {
        contextObj.setInput("serviceUnavailable", true);
        return;
      }
      categoryInSession = sessionStorage.getItem("categoryListData");
      if (categoryInSession) {
        let categoryData = JSON.parse(categoryInSession);
        if (categoryData.data) {
          setCategoriesData(categoryData.data);
        }
      }
    } else {
      if (categoryInSession) {
        let categoryData = JSON.parse(categoryInSession);
        if (categoryData.data) {
          setCategoriesData(categoryData.data);
        }
      }
    }
  };

  useEffect(() => {
    let categoryInSession = sessionStorage.getItem("categoryListData");

    if (categoryInSession == null || categoryInSession == undefined) {
      setTimeout(() => {
        fetchAllCategoriesData(); // Recursive call
      }, 2000);
    } else {
      let categoryData = JSON.parse(categoryInSession);

      setCategoriesData(categoryData.data);
    }
  }, [contextObj.params.featureFlags]);
  const [loadingCategory, setLoadingCategory] = useState(true);

  return (
    <>
      {showFeatures.showCategoryFilter && (
        <div className="category-collection" ref={ShopByCategoryRef}>
          {loadingCategory && categoriesData.length == 0 && (
            <SkeletonComponent className="SkeletonSection categorySkeleton" />
          )}
          {categoriesData.length > 0 && (
            <div className="shop-by-category">
              <span>
                {!loadingCategory && (
                  <h1 className="collection-heading category-heading">
                    Shop by Category
                  </h1>
                )}

                <SwiperComponent
                  className={
                    loadingCategory
                      ? "category_swiper loadingCategory_swiper"
                      : "category_swiper"
                  }
                  slidesPerView={1}
                  spaceBetween={20}
                  freeMode={true}
                  navigation={{ clickable: true }}
                  pagination={{
                    dynamicBullets: true,
                    clickable: true,
                  }}
                  scrollbar={{ draggable: true }}
                  modules={[Navigation, Pagination, FreeMode]}
                  breakpoints={{
                    220: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                      // loop: true,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                      // loop: true,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    425: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                      // loop: true,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    500: {
                      slidesPerView: 4,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    600: {
                      slidesPerView: 5,
                      pagination: { clickable: true, dynamicBullets: true },
                    },
                    768: {
                      slidesPerView: 5,
                    },
                    1024: {
                      slidesPerView: 6,
                    },
                  }}
                >
                  {categoriesData.map((category, index) => (
                    <SwiperSlide
                      key={index}
                      tabIndex={0}
                      onClick={() => {
                        let parentCategoryId =
                          category.parent_category_id == 0
                            ? category.id
                            : category.parent_category_id;
                        pushToArray(
                          "category_id",
                          category.id,
                          parentCategoryId
                        );
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          let parentCategoryId =
                            category.parent_category_id === 0
                              ? category.id
                              : category.parent_category_id;
                          pushToArray(
                            "category_id",
                            category.id,
                            parentCategoryId
                          );
                        }
                      }}
                      onMouseDown={(e) => {
                        if (e.button == 1) {
                          e.preventDefault();
                          window.scrollTo(0, 0);
                          let parentCategoryId =
                            category.parent_category_id == 0
                              ? category.id
                              : category.parent_category_id;
                          pushToArray(
                            "category_id",
                            category.id,
                            parentCategoryId
                          );
                        }
                      }}
                    >
                      <ShopByCategoryElement
                        category={category}
                        loadingCategory={setLoadingCategory}
                      />
                    </SwiperSlide>
                  ))}
                </SwiperComponent>
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShopByCategory;
