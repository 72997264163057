import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import ParamsContext from "../useContext/paramContext";
import { getDataFromCookies, removeDataFromCookie } from "../Cookies/Cookies";
import Constants from "../../config/ProjectConstants.json";
import Toast from "../ReactToastify/Toast";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
const { showToast } = Toast();
import "./cart-sidebar.css";
import LazyImage from "../LazyImage/LazyImage";
const API_HOST = process.env.REACT_APP_API_HOST;

const CartSidebar = (props) => {
  let cartData = [];
  const [cartDeletedItem, setCartDeletedItem] = useState(null);
  const [deletedItemModal, setDeletedItemModal] = useState(false);
  const contextObj = useContext(ParamsContext);
  let [cartProducts, setCartProducts] = useState([]);
  const [showFeatures, setShowFeatures] = useState({
    cartProducts: true,
  });

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        cartProducts: featureFlags.buyer_common_addtocartbar,
      }));
    }
  }, [contextObj.params.featureFlags]);

  const navigate = useNavigate();
  function calculateOfferPercentage(actualPrice, sellingPrice) {
    if (actualPrice && sellingPrice) {
      if (actualPrice <= 0 || sellingPrice <= 0) {
        throw new Error("Price must be greater than zero.");
      }
      const discount = actualPrice - sellingPrice;
      const offerPercentage = Math.abs(
        Math.round((discount / actualPrice) * 100)
      );
      return offerPercentage;
    }
  }

  function calculateTotalAmount(cartProducts) {
    const totalAmount = cartProducts.reduce((total, item) => {
      if (item.product && item.product.is_stock_available === 1) {
        return total + item.product.selling_price;
      }
      return total;
    }, 0);
    return totalAmount;
  }

  const getCheckoutItmesArray = () => {
    let checkoutItmes = [];
    cartProducts.forEach((element) => {
      if (element.product.is_stock_available == 1)
        checkoutItmes.push(element.product.id);
    });
    if (checkoutItmes.length > 0) {
      contextObj.setInput("checkoutProducts", checkoutItmes);
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "cartPage");
      showToast("success", Constants.alertMessages.alertForCheckout);
      navigate("/checkout");
    }
  };
  // Function to format a number with commas
  function formatNumberWithCommas(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number;
  }

  const fetchCartData = async () => {
    let isLoggedIn = getDataFromCookies("isLoggedIn");
    if (isLoggedIn == true) {
      const authenticationToken = getDataFromCookies("7H3K9R5P2Q");
      const response = await fetch(
        `${API_HOST}/${endpoints.buyerCartDetails}`,
        {
          headers: {
            Authorization: `Bearer ${authenticationToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const cartResponse = await response.json();
      if (
        cartResponse.status == 500 ||
        cartResponse.status == 501 ||
        cartResponse.status == 502 ||
        cartResponse.status == 503 ||
        (cartResponse.message &&
          cartResponse.message.toLowerCase().includes("server error"))
      ) {
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      if (cartResponse.status === 200) {
        if (cartResponse.data && cartResponse.data.deleted_items !== null) {
          setDeletedItemModal(true);
          setCartDeletedItem(cartResponse.data.deleted_items);
        }
        cartData = cartResponse.data.cart;

        setCartProducts(cartData);
        contextObj.setInput("cartLength", cartData.length);
      }
      if (cartResponse.status == 401) {
        removeDataFromCookie("7H3K9R5P2Q", "/");
        removeDataFromCookie("isLoggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        contextObj.setInput("isLoggedIn", false);
        navigate(`${navigation.navigateToHomepage}`);
      }
    }
  };
  const clearedDeletedItems = async () => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    const response = await fetch(
      `${API_HOST}/${endpoints.clearedCartDeletedItems}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      contextObj.setInput(
        "cartLength",
        contextObj.params.cartLength - cartDeletedItem
      );
    } else if (res.status == 401) {
      removeDataFromCookie("7H3K9R5P2Q", "/");
      removeDataFromCookie("isLoggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    } else if (
      res.status == 500 ||
      res.status == 501 ||
      res.status == 502 ||
      res.status == 503 ||
      (res.message && res.message.toLowerCase().includes("server error"))
    ) {
      if (res.status == 503) setMaintainanceMode(true);
      else setMaintainanceMode(false);
      contextObj.setInput("serviceUnavailable", true);
    } else {
      contextObj.setInput("serviceUnavailable", false);
    }
    setDeletedItemModal(false);
    await fetchCartData();
  };
  useEffect(() => {
    if (props.fetchDataNow == true) {
      props.resetFetchDataNow(false);
      fetchCartData();
    }
  }, [props]);
  return (
    <>
      {showFeatures.cartProducts && (
        <>
          {cartProducts && cartProducts.length > 0 ? (
            <div
              className="cart-sidebar-container"
              onClick={() => {
                contextObj.setInput("showCartSidebar", false);
              }}
              onScroll={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div
                className="cart-sidebar-modal"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="cart-sidebar-header">
                  <div className="sidebar-heading">
                    My Cart ({cartProducts.length})
                  </div>
                  <FontAwesomeIcon
                    icon={faTimes}
                    id="closeCartSidebarBtn"
                    className="sidebar-close-icon"
                    onClick={(e) => {
                      contextObj.setInput("showCartSidebar", false);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                </div>
                <div className="cart-sidebar-body">
                  <p className="cart-sidebar-summary-heading">Order Summary</p>
                  {cartProducts.map(
                    (item, index) =>
                      item.product && (
                        <>
                          <div
                            className="cart-sidebar-product"
                            id={`sidebarProduct_${index}`}
                            key={item.id}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (item.product.is_stock_available == 1) {
                                contextObj.setInput("showCartSidebar", false);
                                navigate(
                                  `${navigation.navigateToProductList}/${item.product.slug}`
                                );
                              }
                            }}
                          >
                            <LazyImage
                              className="cart-sidebar-image"
                              id={`sidebarProductImg_${index}`}
                              src={imgHost + "/" + item.product.thumb_image}
                              alt="image"
                            />

                            <div className="cart-sidebar-product-details">
                              <p
                                className="cart-sidebar-product-name"
                                id={`sidebarProductName_${index}`}
                              >
                                {item.product.name}
                              </p>
                              <div>
                                <div className="sidebar-price-line">
                                  <p className="sidebar-product-price">
                                    ₹{" "}
                                    <span>
                                      {formatNumberWithCommas(
                                        item.product.selling_price
                                      )}
                                    </span>
                                  </p>
                                  {calculateOfferPercentage(
                                    item.product.current_market_price,
                                    item.product.selling_price
                                  ) > 0 && (
                                    <p className="sidebar-product-discount">
                                      {" "}
                                      {calculateOfferPercentage(
                                        item.product.current_market_price,
                                        item.product.selling_price
                                      )}
                                      % off
                                    </p>
                                  )}
                                </div>
                                <p
                                  className="sidebar-product-mrp"
                                  id={`sidebarProductOriginalPrice_${index}`}
                                >
                                  (Est. Original Price:{" "}
                                  <span>
                                    ₹{" "}
                                    {formatNumberWithCommas(
                                      item.product.current_market_price
                                    )}
                                  </span>
                                  )
                                </p>
                              </div>
                            </div>
                          </div>
                          {item.product.is_stock_available == 0 && (
                            <p className="sidebar-out-of-stock">Out of Stock</p>
                          )}
                          <hr className="cart-sidebar-product-divider" />
                        </>
                      )
                  )}
                </div>
                <div className="cart-sidebar-footer">
                  <div className="sidebar-total">
                    <p>Sub Total</p>
                    <p className="sidebar-total-amount">
                      ₹{" "}
                      {formatNumberWithCommas(
                        calculateTotalAmount(cartProducts)
                      )}
                    </p>
                  </div>
                  <div className="cart-sidebar-buttons">
                    <button
                      className="sidebar-cart-btn"
                      id="cartSidebarNavigateToCart"
                      onClick={(e) => {
                        contextObj.setInput("showCartSidebar", false);
                        e.preventDefault();
                        e.stopPropagation();
                        navigate(`${navigation.navigateToNewCartPage}`);
                      }}
                    >
                      View Cart
                    </button>
                    <button
                      className="sidebar-checkout-btn"
                      id="cartSidebarNavigateToCheckout"
                      onClick={(e) => {
                        contextObj.setInput("showCartSidebar", false);
                        e.preventDefault();
                        e.stopPropagation();
                        getCheckoutItmesArray();
                      }}
                    >
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="cart-sidebar-container"
              onScroll={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className="cart-sidebar-modal">
                <h5 className="sidebar-loading">Loading...</h5>
              </div>
            </div>
          )}
        </>
      )}
      {deletedItemModal && (
        <div className="warning-modal ModalContentForCart">
          <div className="modal-container warning-modal-container">
            <h1>Warning</h1>
            <p className="message">
              Oops, {cartDeletedItem} items in your cart no longer available.
            </p>

            <div className="modal-buttons">
              <button
                type="button"
                className="warning-rm-btn"
                onClick={() => {
                  clearedDeletedItems();
                }}
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CartSidebar;
