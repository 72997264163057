import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ParamsContext from "../useContext/paramContext";
import BannerSlider from "./BannerSlider";
import ShopByCategory from "./ShopByCategory";
// import  TestCategorySlider from  "./testCategorySlider";
import RecentlyViewedProducts from "./RecentlyViewedProducts";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import Endpoints from "../../config/APIEndpoints.json";
import { getDataFromCookies } from "../Cookies/Cookies";
import Swiper from "./Swiper";
import HomePageSkeleton from "./HomePageSkeleton";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import Testimonials from "./Testimonials";
import "./home.css";
import "./skeleton.css";
import BlogsAndNews from "./BlogsAndNews";
import SquareColletion from "./SquareColletion";
import LazyImage from "../LazyImage/LazyImage";
import SkeletonComponent from "../skeleton/Skeleton";
import Navigation from "../../config/Navigation.json";
import { Helmet } from "react-helmet";

import Footer1 from "../Footer/Footer1";
import Footer2 from "../Footer/Footer2";

const API_HOST = process.env.REACT_APP_API_HOST;

const infra = process.env.REACT_APP_INFRA;
const Home = () => {
  function filterEmptyProducts(data) {
    return data.filter((item) => item.products.length != 0);
  }
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentURL = window.location.origin + pathname;
  const [newArrivalsProducts, setNewArrivalsProducts] = useState([]);
  const [mostPopularProducts, setMostPopularProducts] = useState([]);
  const [dealsOfTheWeekProducts, setDealsOfTheWeekProducts] = useState([]);

  const collectionRef = useRef(null);

  const newArrivalsRef = useRef(null);
  const mostPopularRef = useRef(null);
  const dealsOfTheWeekRef = useRef(null);
  const allCollectionsRef = useRef(null);
  const bottomSkeletonRef = useRef(null);

  const [bottomSkeletonView, setBottomSkeletonView] = useState(false);

  const [render, setRender] = useState(false);

  function pushToArray(columnName, value) {
    sessionStorage.removeItem("filterParamsUpdated");
    let filterParamsUpdated = [
      {
        columnName: "brand_id",
        values: [],
      },
      {
        columnName: "category_id",
        values: [],
      },
      {
        columnName: "age_group",
        values: [],
      },
      {
        columnName: "condition",
        values: [],
      },
      {
        columnName: "price_range",
        values: [],
      },
      {
        columnName: "sort",
        values: [],
      },
    ];
    for (let i = 0; i < filterParamsUpdated.length; i++) {
      if (
        filterParamsUpdated[i].columnName == columnName &&
        !filterParamsUpdated[i].values.includes(value)
      ) {
        filterParamsUpdated[i].values.push(value);
        break;
      }
    }

    sessionStorage.setItem(
      "filterParamsUpdated",
      JSON.stringify(filterParamsUpdated)
    );
    window.open(`${Navigation.navigateToProductList}`, "_blank");
  }
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    buyer_homepage_categoryview: false,
    buyer_collection_new_arrivals: true,
    buyer_collection_most_popular_products: true,
    buyer_collection_deals_for_you: true,
    showCategoryFilter: true,
    showAgeFilter: true,
    showConditionFilter: true,
    showPriceFilter: true,
    buyer_common_footer_splitted: true,
    featureUpdated: false,
  });

  useEffect(() => {
    let featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        buyer_homepage_categoryview: featureFlags.buyer_homepage_categoryview,
        buyer_collection_new_arrivals:
          featureFlags.buyer_collection_new_arrivals,
        buyer_collection_most_popular_products:
          featureFlags.buyer_collection_most_popular_products,
        buyer_collection_deals_for_you:
          featureFlags.buyer_collection_deals_for_you,
        showCategoryFilter: featureFlags.buyer_products_categories,
        showAgeFilter: featureFlags.buyer_products_age,
        showConditionFilter: featureFlags.buyer_products_condition,
        showPriceFilter: featureFlags.buyer_products_price,
        buyer_common_footer_splitted: featureFlags.buyer_common_footer_splitted,
        featureUpdated: true,
      }));
    }
  }, [contextObj.params.featureFlags]);

  const [conditionsData, setConditionsData] = useState([]);
  const [applicableAgeGroupData, setApplicableAgeGroupData] = useState([]);
  const [pricesData, setPricesData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);

  // skeleton
  const [loadingAgeGroupData, setLoadingAgeGroupData] = useState(true);
  const [loadingPricesData, setLoadingPricesData] = useState(true);
  const [loadingConditionsData, setLoadingConditionsDataData] = useState(true);

  const [loadingNewArrivalsData, setLoadingNewArrivalsData] = useState(true);
  const [loadingMostPopularProductsData, setLoadingMostPopularProductsData] =
    useState(true);
  const [loadingDealseOfTheWeekData, setLoadingDealseOfTheWeekData] =
    useState(true);
  const [loadingCollection, setLoadingCollection] = useState(true);

  useEffect(() => {
    if (
      contextObj.params.navigation == "true" &&
      contextObj.params.navigationFrom == "sessionExpired"
    ) {
      contextObj.setInput("showLoginModel", true);
      contextObj.setInput("navigation", "false");
      contextObj.setInput("navigationFrom", "");
    }
  }, [contextObj.params.navigation]);

  useEffect(() => {
    if (contextObj.params.refetchAfterLoginAddWishList) {
      contextObj.setInput("refetchAfterLoginAddWishList", false);
    }
  }, [contextObj.params.refetchAfterLoginAddWishList]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.01,
    };

    const callback = (entries) => {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
          setBottomSkeletonView(false);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (bottomSkeletonRef.current) {
      observer.observe(bottomSkeletonRef.current);
    }

    return () => {
      if (bottomSkeletonRef.current) {
        observer.unobserve(bottomSkeletonRef.current);
      }
    };
  }, []);

  const getNewArrivals = async () => {
    await fetch(`${API_HOST}/${Endpoints.getProducts}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status == 200) {
          setNewArrivalsProducts(res.data.data);
        }

        setLoadingNewArrivalsData(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      });
  };

  const getMostPopularProducts = async () => {
    await fetch(`${API_HOST}/${Endpoints.mostPopularProducts}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status == 200) {
          setMostPopularProducts(res.data);
        }
        setLoadingMostPopularProductsData(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      });
  };

  const getDealsOfTheWeekProducts = async () => {
    await fetch(`${API_HOST}/${Endpoints.dealsOfTheWeekProducts}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status == 200) {
          setDealsOfTheWeekProducts(res.data);
        }

        setLoadingDealseOfTheWeekData(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      });
  };

  const getProductConditionsData = async () => {
    try {
      const response = await fetch(
        `${API_HOST}/${Endpoints.getProductConditions}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const res = await response.json();
      if (
        res.status == 500 ||
        res.status == 501 ||
        res.status == 502 ||
        res.status == 503 ||
        (res.message && res.message.toLowerCase().includes("server error"))
      ) {
        if (res.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      if (res.data) {
        setConditionsData(res.data);
      } else {
        setLoadingConditionsDataData(false);
      }
    } catch (error) {
      setLoadingConditionsDataData(false);

      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      }
    }
  };

  const getAllPriceData = async () => {
    try {
      const response = await fetch(
        `${API_HOST}/${Endpoints.getPriceCollections}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();
      if (
        res.status == 500 ||
        res.status == 501 ||
        res.status == 502 ||
        res.status == 503 ||
        (res.message && res.message.toLowerCase().includes("server error"))
      ) {
        if (res.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }
      if (res.data) {
        setPricesData(res.data);
      } else {
        setLoadingPricesData(false);
      }
    } catch (error) {
      setLoadingPricesData(false);
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      }
    }
  };

  const getapplicableAgeGroupData = async () => {
    try {
      const response = await fetch(
        `${API_HOST}/${Endpoints.getApplicableAgeGroup}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();
      if (
        res.status == 500 ||
        res.status == 501 ||
        res.status == 502 ||
        res.status == 503 ||
        (res.message && res.message.toLowerCase().includes("server error"))
      ) {
        if (res.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }

      if (res.data) {
        setApplicableAgeGroupData(res.data);
      } else {
        setLoadingAgeGroupData(false);
      }
    } catch (error) {
      setLoadingAgeGroupData(false);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      }
    }
  };

  const getAllCollections = async () => {
    let authenticationToken = contextObj.params.isLoggedIn
      ? getDataFromCookies("7H3K9R5P2Q")
      : "";
    try {
      const response = await fetch(
        `${API_HOST}/${Endpoints.getAllCollections}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authenticationToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();

      if (
        res.status == 500 ||
        res.status == 501 ||
        res.status == 502 ||
        res.status == 503 ||
        (res.message && res.message.toLowerCase().includes("server error"))
      ) {
        if (res.status == 503) {
          setMaintainanceMode(true);
        } else {
          setMaintainanceMode(false);
        }
        contextObj.setInput("serviceUnavailable", true);
      } else {
        contextObj.setInput("serviceUnavailable", false);
      }

      if (res.data) {
        let resData = res.data;
        let updatedResData = filterEmptyProducts(resData);

        if (updatedResData.length == 0) {
          setLoadingCollection(false);
        } else {
          setCollectionData(updatedResData);
        }
      } else {
        setLoadingCollection(false);
      }
    } catch (error) {
      setLoadingCollection(false);
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        contextObj.setInput("serviceUnavailable", true);
      }
    }
  };

  useEffect(() => {
    getapplicableAgeGroupData();
    getAllPriceData();
    getProductConditionsData();
    getNewArrivals();
    getMostPopularProducts();
    getDealsOfTheWeekProducts();
    getAllCollections();
    // setRender(false);
  }, []);

  // useEffect(() => {
  //   console.log("dataLoaded =====>", dataLoaded);
  //   if (
  //     dataLoaded.banner &&
  //     dataLoaded.age &&
  //     dataLoaded.price &&
  //     dataLoaded.conditions &&
  //     dataLoaded.category
  //   ) {
  //     setRender(false);
  //   }
  // }, [
  //   dataLoaded.banner,
  //   dataLoaded.age,
  //   dataLoaded.price,
  //   dataLoaded.conditions,
  //   dataLoaded.category,
  // ]);
  function updateCollectionData(collectionItems, id) {
    const index = collectionItems.findIndex((product) => product.id === id);
    if (index !== -1 && collectionItems[index].is_in_wishlist === false) {
      collectionItems[index].is_in_wishlist = true;
    }
  }

  useEffect(() => {
    if (contextObj.params.productAddedToWishlistId) {
      let productId = contextObj.params.productAddedToWishlistId;
      const timeoutId = setTimeout(() => {
        if (contextObj.params.productAddedToWishlistId !== "") {
          {
            collectionData &&
              collectionData.map((collection, index) =>
                updateCollectionData(collection.products, productId)
              );
          }
        }
      }, 10);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [contextObj.params.productAddedToWishlistId]);
  let [bannerLoading, setBannerLoading] = useState(false);

  return (
    <>
      {infra == "production" && (
        <Helmet>
          <title>
            JOYREJOY - Bringing joy back to pre-loved toys - one sale at a time!
          </title>
          <meta
            name="description"
            content="Bringing joy back to pre-loved toys - one sale at a time! Find a wide range of high-quality second-hand toys at JOYREJOY."
          />
          <link rel="canonical" href={currentURL} />
        </Helmet>
      )}

      <>
        {contextObj.params.serviceUnavailable == true ? (
          <ServiceUnavailable maintainanceMode={maintainanceMode} />
        ) : (
          <>
            {render && (
              <div className="body-container">
                <HomePageSkeleton />
              </div>
            )}
            <div
              style={{
                opacity: render ? 0 : 1,
                height: render ? "0px" : "auto",
                overflow: "auto",
                // border: "1px solid red",
              }}
            >
              <div className="pageParentDiv">
                {/* Bannar section */}
                <div className="banner-container">
                  <BannerSlider setBannerLoaded={setBannerLoading} />
                </div>

                <div className="body-container">
                  {/* Square collection start from here */}

                  <div
                    className="brands-conditions-square-row"
                    ref={collectionRef}
                  >
                    {bannerLoading &&
                      applicableAgeGroupData.length == 0 &&
                      loadingAgeGroupData && (
                        <SkeletonComponent className="SkeletonSection collectionSkeleton" />
                      )}
                    {showFeatures.showAgeFilter &&
                      applicableAgeGroupData.length > 0 && (
                        <div className="top-brands">
                          <div className="collection-heading-row">
                            <h1 className="square-collection-heading">
                              Shop by Age
                            </h1>
                            <div className="collection-hr" />
                          </div>
                          <div className="brand-collections">
                            {applicableAgeGroupData &&
                              applicableAgeGroupData.map((age, index) => (
                                <div
                                  key={index}
                                  className="collectionImageContainer"
                                  onClick={() => {
                                    pushToArray(
                                      "age_group",
                                      `${age.min}-${age.max}`
                                    );
                                  }}
                                  onMouseDown={(e) => {
                                    if (e.button === 1) {
                                      e.preventDefault();
                                      window.scrollTo(0, 0);
                                      pushToArray(
                                        "age_group",
                                        `${age.min}-${age.max}`
                                      );
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      pushToArray(
                                        "age_group",
                                        `${age.min}-${age.max}`
                                      );
                                    }
                                  }}
                                  role="button"
                                  tabIndex={0}
                                >
                                  <LazyImage
                                    src={`${imgHost}/${age.image}`}
                                    alt={age.title}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    loadingImg={setLoadingAgeGroupData}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      )}

                    {bannerLoading &&
                      pricesData.length == 0 &&
                      loadingPricesData && (
                        <SkeletonComponent className="SkeletonSection collectionSkeleton" />
                      )}
                    {showFeatures.showPriceFilter && pricesData.length > 0 && (
                      <div className="shop-by-conditions ">
                        <div className="collection-heading-row">
                          <h1 className="square-collection-heading">
                            Shop by Price
                          </h1>
                          <div className="collection-hr" />
                        </div>
                        <div className="conditions-collections">
                          {pricesData &&
                            pricesData.map((price, index) => (
                              <div
                                key={index}
                                className="collectionImageContainer"
                                onClick={() => {
                                  pushToArray(
                                    "price_range",
                                    `${price.min}-${price.max}`
                                  );
                                }}
                                onMouseDown={(e) => {
                                  if (e.button == 1) {
                                    e.preventDefault();
                                    window.scrollTo(0, 0);
                                    pushToArray(
                                      "price_range",
                                      `${price.min}-${price.max}`
                                    );
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    pushToArray(
                                      "price_range",
                                      `${price.min}-${price.max}`
                                    );
                                  }
                                }}
                                role="button"
                                tabIndex={0}
                              >
                                <LazyImage
                                  src={`${imgHost}/${price.image}`}
                                  alt={price.title}
                                  loading="lazy"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  loadingImg={setLoadingPricesData}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                    {bannerLoading &&
                      conditionsData.length == 0 &&
                      loadingConditionsData && (
                        <SkeletonComponent className="SkeletonSection collectionSkeleton" />
                      )}
                    {showFeatures.showConditionFilter &&
                      conditionsData.length > 0 && (
                        <div className="shop-by-age ">
                          <div className="collection-heading-row">
                            <h1 className="square-collection-heading">
                              Shop by Conditions
                            </h1>
                            <div className="collection-hr" />
                          </div>
                          <div className="age-collections">
                            {conditionsData &&
                              conditionsData.map((condition, index) => (
                                <div
                                  key={index}
                                  className="collectionImageContainer"
                                  onClick={() => {
                                    pushToArray("condition", condition.title);
                                  }}
                                  onMouseDown={(e) => {
                                    if (e.button == 1) {
                                      e.preventDefault();
                                      window.scrollTo(0, 0);
                                      pushToArray("condition", condition.title);
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      pushToArray("condition", condition.title);
                                    }
                                  }}
                                  role="button"
                                  tabIndex={0}
                                >
                                  <LazyImage
                                    src={`${imgHost}/${condition.image}`}
                                    alt="image"
                                    loading="lazy"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    loadingImg={setLoadingConditionsDataData}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                  </div>

                  {/* Shop by category section */}
                  {!bannerLoading && (
                    <div>
                      <ShopByCategory />
                    </div>
                  )}

                  {/* New Arrivals */}
                  {showFeatures.buyer_collection_new_arrivals &&
                    !bannerLoading &&
                    !loadingNewArrivalsData &&
                    newArrivalsProducts.length > 0 && (
                      <div
                        className="landingPageCollectionContainer"
                        ref={newArrivalsRef}
                        id="newArrivalsSection"
                      >
                        {loadingNewArrivalsData && (
                          <SkeletonComponent className="SkeletonSection newArrivalsSkeleton" />
                        )}

                        <div>
                          <div className="collection-heading-row">
                            <h1 className="collection-heading">New Arrivals</h1>
                            <div className="collection-hr" />
                          </div>
                          <Swiper
                            products={newArrivalsProducts}
                            loadingImg={setLoadingNewArrivalsData}
                          />
                        </div>
                      </div>
                    )}

                  {/* Most Polular Products */}
                  {showFeatures.buyer_collection_most_popular_products && (
                    <div
                      className="landingPageCollectionContainer"
                      ref={mostPopularRef}
                      id="MostPopularProductsSection"
                    >
                      {loadingMostPopularProductsData && (
                        <SkeletonComponent className="SkeletonSection newArrivalsSkeleton" />
                      )}
                      {!bannerLoading &&
                        !loadingMostPopularProductsData &&
                        mostPopularProducts.length > 0 && (
                          <div>
                            <div className="collection-heading-row">
                              <h1 className="collection-heading">
                                Most Popular Products
                              </h1>
                              <div className="collection-hr" />
                            </div>
                            <Swiper
                              products={mostPopularProducts}
                              loadingImg={setLoadingNewArrivalsData}
                            />
                          </div>
                        )}
                    </div>
                  )}

                  {/* Deals of the Week Products */}
                  {showFeatures.buyer_collection_deals_for_you && (
                    <div
                      className="landingPageCollectionContainer"
                      ref={dealsOfTheWeekRef}
                    >
                      {loadingDealseOfTheWeekData && (
                        <SkeletonComponent className="SkeletonSection newArrivalsSkeleton" />
                      )}
                      {!loadingDealseOfTheWeekData &&
                        dealsOfTheWeekProducts.length > 0 && (
                          <div id="collection_Deals_Of_The_Week">
                            <div className="collection-heading-row">
                              <h1 className="collection-heading">
                                Deals Of The Week
                              </h1>
                              <div className="collection-hr" />
                            </div>
                            <Swiper
                              products={dealsOfTheWeekProducts}
                              loadingImg={setLoadingDealseOfTheWeekData}
                            />
                          </div>
                        )}
                    </div>
                  )}

                  {!bannerLoading &&
                    showFeatures.buyer_homepage_categoryview == true &&
                    collectionData.length == 0 && <ShopByCategory />}

                  {/* All collections */}

                  <div className="collections" ref={allCollectionsRef}>
                    {bannerLoading &&
                      loadingCollection &&
                      collectionData.length == 0 && (
                        <SkeletonComponent className="SkeletonSection collectionSkeleton" />
                      )}
                    {collectionData &&
                      collectionData.map((collection, index) => (
                        <>
                          {collection.products &&
                            collection.products.length > 0 && (
                              <div
                                key={index}
                                id={`collection_${collection.title
                                  .split(" ")
                                  .join("_")} `}
                              >
                                <div className="collection-heading-row">
                                  <h1 className="collection-heading">
                                    {collection.title}
                                  </h1>
                                  <div className="collection-hr" />
                                </div>

                                <Swiper
                                  products={collection.products}
                                  loadingImg={
                                    index == collectionData.length - 1
                                      ? setLoadingCollection
                                      : undefined
                                  }
                                />
                              </div>
                            )}
                        </>
                      ))}
                  </div>

                  {/* Recently viewd items */}

                  <RecentlyViewedProducts />

                  {/* Seller Testimonials */}

                  <Testimonials />

                  {/* blogs and news */}

                  <BlogsAndNews />
                </div>
              </div>
              {/* footer */}

              {showFeatures.featureUpdated && (
                <div>
                  {showFeatures.buyer_common_footer_splitted ? (
                    <Footer2 />
                  ) : (
                    <Footer1 />
                  )}
                </div>
              )}
            </div>
            {/* )} */}
          </>
        )}
      </>
    </>
  );
};

export default Home;
