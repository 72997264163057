import React, { useEffect, useState, useContext } from "react";
import "./CategoryDropDown.css";
import ParamsContext from "../useContext/paramContext";
import Navigation from "../../config/Navigation.json";
import { useNavigate } from "react-router-dom";
const CategoryDropDown = (props) => {
  const navigate = useNavigate();
  const contextObj = useContext(ParamsContext);
  let filterParamsUpdated = [
    {
      columnName: "brand_id",
      values: [],
    },
    {
      columnName: "category_id",
      values: [],
    },
    {
      columnName: "age_group",
      values: [],
    },
    {
      columnName: "condition",
      values: [],
    },
    {
      columnName: "price_range",
      values: [],
    },
    {
      columnName: "sort",
      values: [],
    },
  ];

  const navigateToCategory = async (categoryId, parentCategoryId) => {
    props.setSelectedCategoryId(parentCategoryId);
    const session_filterParamsUpdated = sessionStorage.getItem(
      "filterParamsUpdated"
    );
    if (session_filterParamsUpdated !== null) {
      filterParamsUpdated = await JSON.parse(session_filterParamsUpdated);
    }
    for (let i = 0; i < filterParamsUpdated.length; i++) {
      if (filterParamsUpdated[i].columnName == "category_id") {
        filterParamsUpdated[i].values == [];
        filterParamsUpdated[i].values = [categoryId];
        break;
      }
    }
    sessionStorage.setItem("selectedCategoryId", parentCategoryId);
    sessionStorage.setItem(
      "filterParamsUpdated",
      JSON.stringify(filterParamsUpdated)
    );
    await contextObj.setInput("categoryFilterApplied", true);

    navigate(`${Navigation.navigateToProductList}`);
  };
  let [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    if (props.childCategories) {
      setCategoryList(props.childCategories);
    }
  }, [props]);

  return (
    <div
      className="childCategoriesDropDown"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="childCategoriesList">
        {categoryList &&
          categoryList.map((category) => {
            return (
              <div
                className="childCategoryName"
                onClick={() => {
                  navigateToCategory(category.id, category.parent_category_id);
                  props.closeCategoryDropDown(props.item.id)
                }}
              >
                {category.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CategoryDropDown;
