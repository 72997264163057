import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UpdateAddressPage from "../Address/updateAddress";
import AddressPage from "../Address/addressMQ";
import ParamsContext from "../useContext/paramContext";
import endpoints from "../../config/APIEndpoints.json";
import navigation from "../../config/Navigation.json";
import Constants from "../../config/ProjectConstants.json";
import { setDataToCookies } from "../Cookies/Cookies";
import ServiceUnavailable from "../serviceUnavailable/serviceUnavailable";
import { getDataFromCookies, removeDataFromCookie } from "../Cookies/Cookies";
import AddressEmptyImage from "../../Assets/location.webp";
import Wright from "../../Assets/Wright.webp";
import { moveIsDefaultToFirstIndex } from "../../resuableFunctions/resuableFunctions";
import placeHolderImage from "../../Assets/placeHolderImage.webp";
import CheckoutPageRadioBtn from "./cheoutPageRadioBtn";
import Toast from "../ReactToastify/Toast";
import CheckoutSkeleton from "./CheckoutSkeleton";
import loadingImgPath from "../../Assets/rolling.svg";
import { imgHost } from "../../resuableFunctions/resuableFunctions";
import "./CheckoutPage.css";
import LazyImage from "../LazyImage/LazyImage";
const API_HOST = process.env.REACT_APP_API_HOST;
let validShipmentToken = true;

const CheckoutPage = () => {
  const { showToast } = Toast();
  const [reFetchAddress, setReFetchAddress] = useState(true);
  const [notDeliverableProducts, setNotDeliverableProducts] = useState([]);
  const [defaultRender, setdefaultRender] = useState(true);
  const [maintainanceMode, setMaintainanceMode] = useState(false);
  const [navigationFromProductDetails, setNavigationFromProductDetails] =
    useState(false);
  const contextObj = useContext(ParamsContext);
  const [showFeatures, setShowFeatures] = useState({
    myOrders: true,
    searchbar: true,
  });

  // Remove duplicats
  function removeDuplicates(DuplicateIds) {
    const updatedProducts = products.map((prod) => {
      let currentProduct = prod.product;
      if (DuplicateIds.includes(currentProduct.id)) {
        return (prod.product = { ...currentProduct, outOfStock: true });
      } else {
        return prod;
      }
    });
    products.forEach((prod, index) => {
      prod = updatedProducts[index];
    });
    setProducts(products);
    const filteredArray = contextObj.params.checkoutProducts.filter(
      (element) => !DuplicateIds.includes(element)
    );
    contextObj.setInput("checkoutProducts", filteredArray);
  }

  const navigate = useNavigate();
  const [loadingPlaceOrderBtn, setLoadingPlaceOrderBtn] = useState(false);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [disablePlaceOrder, setDisablePlaceOrder] = useState(false);
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [placeOrderError, setPlaceOrderError] = useState("");
  const [products, setProducts] = useState([]);
  const [SubtotalPrice, setSubtotalPrice] = useState(0);
  const [totalShippingCharges, setTotalShippingCharges] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);
  const [addressList, setAddressList] = useState([]);
  const [showAddressList, setShowAddressList] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [updatedAddressId, setUpdatedAddressId] = useState("");
  const [selectNewAddress, setSelectNewAddress] = useState(false);
  const [addressAvailable, setAddressAvailable] = useState(false);
  const [fetchProductsWithoutAdd, setFetchProductsWithoutAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disabledPlaceOrder, setDisabledPlaceOrder] = useState(false);

  const [expanded, setExpanded] = useState({
    addresList: true,
    productList: false,
  });

  const [totalPrice, setTotalPrice] = useState({
    products: 0,
    shippingCharges: 0,
  });
  useEffect(() => {
    if (showEditAddressModal || contextObj.params.showAddressModal) {
      document.body.style.overflow = "hidden"; // Disable scroll when the modal is open
      return () => {
        document.body.style.overflow = "auto"; // Enable scroll when the modal is closed
      };
    }
  }, [showEditAddressModal, contextObj.params.showAddressModal]);
  const handleCloseModal = async () => {
    if (reFetchAddress) {
      setLoading(true);
      await fetchUserAddressList();
      await getProductsDetails();
    }
    contextObj.setInput("showAddressModal", false);
  };

  const handleCloseEditModal = async () => {
    setShowEditAddressModal(false);
    if (reFetchAddress) {
      setLoading(true);
      await fetchUserAddressList();
      await getProductsDetails();
    }
  };

  const [disableAddAddress, setDisableAddAddress] = useState(false);

  useEffect(() => {
    if (addressList.length == 10) {
      setDisableAddAddress(true);
    }
  }, [addressList]);
  const [brandData, setBrandData] = useState();

  useEffect(() => {
    const brand = sessionStorage.getItem("brandsListData"); // getDataFromCookies("brandsListData");
    if (brand !== null) {
      let brandData = JSON.parse(brand);
      brandData = brandData.data;
      setBrandData(brandData);
    }
  }, []);

  useEffect(() => {
    const featureFlags = contextObj.params.featureFlags;
    if (featureFlags) {
      setShowFeatures((prev) => ({
        ...prev,
        showWishList: featureFlags.buyer_products_wishlist,
        myOrders: featureFlags.buyer_profile_myorders,

        searchbar: featureFlags.buyer_products_search,
      }));
    }
  }, [contextObj.params.featureFlags]);

  const calculateTotalPrice = (items) => {
    if (!items || !Array.isArray(items)) {
      setSubtotalPrice(0);
      setTotalShippingCharges(0);
      setTotalSavings(0);
      return;
    }

    let totalPrice = 0;
    let totalShippingCharges = 0;
    let onrignalPrice = 0;

    for (const item of items) {
      if (item.shipping_cost) {
        if (addressAvailable) {
          if (item && typeof item.product.selling_price === "number") {
            totalPrice += item.product.selling_price;
          }
          if (item && typeof item.shipping_cost === "number") {
            totalShippingCharges += item.shipping_cost;
          }
          if (item && typeof item.product.current_market_price === "number") {
            onrignalPrice += item.product.current_market_price;
          }
        } else {
          if (item && typeof item.selling_price === "number") {
            totalPrice += item.selling_price;
          }
        }
      }
    }
    let savings = onrignalPrice - totalPrice - totalShippingCharges;
    setSubtotalPrice(Math.round(totalPrice));
    setTotalShippingCharges(Math.round(totalShippingCharges));
    setTotalSavings(Math.round(savings));

    return;
  };

  function calculateTotalForOrigionalPrice(checkoutProducts) {
    const totalAmount = checkoutProducts.reduce((total, item) => {
      if (item) {
        return total + item.product.current_market_price;
      }
      return total;
    }, 0);
    return totalAmount;
  }

  useEffect(() => {
    if (addressAvailable) {
      products.length > 0 &&
        products.map((product) => {
          setTotalPrice((prev) => ({
            ...prev,
            products: totalPrice.products + product.productselling_price,
          }));
        });
    }
  }, [products]);

  useEffect(() => {
    calculateTotalPrice(products);
  }, [products]);

  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const handleUpdateCancel = () => {
    setShowUpdateProfile(false);
  };
  const [profileCompleted, setProfileCompleted] = useState(true);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [isFirstAddress, setIsFirstAddress] = useState(false);
  function calculateOfferPercentage(actualPrice, sellingPrice) {
    if (actualPrice && sellingPrice) {
      if (actualPrice <= 0 || sellingPrice <= 0) {
        throw new Error("Price must be greater than zero.");
      }
      const discount = actualPrice - sellingPrice;
      const offerPercentage = Math.abs(
        Math.round((discount / actualPrice) * 100)
      );

      return offerPercentage;
    }
  }
  const handleAddAddress = async () => {
    if (profileCompleted) {
      if (isFirstAddress) {
        setDataToCookies("hideIsdefaultInAddAddress", true, "/");
      } else {
        setDataToCookies("hideIsdefaultInAddAddress", false, "/");
      }
      setSelectNewAddress(true);
      contextObj.setInput("showAddressModal", true);
    } else {
      setShowUpdateProfile(true);
    }
  };
  const fetchUserAddressList = async () => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    await fetch(`${API_HOST}/${endpoints.buyerShippingAddress}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        if (res.status === 404) {
          setDisabledPlaceOrder(true);
          setIsFirstAddress(true);
          setAddressAvailable(false);
          setFetchProductsWithoutAdd(true);
          setShowAddressList(false);
          setAddressList([]);
          if (res.data.is_user_profile_complete == false) {
            setProfileCompleted(false);
          }
        }

        if (res.status === 200) {
          setDisabledPlaceOrder(false);
          setIsFirstAddress(false);
          setProfileCompleted(true);

          let updatedAdd = res.data;
          if (selectNewAddress !== true) {
            updatedAdd = moveIsDefaultToFirstIndex(res.data);
          }
          setAddressList(updatedAdd);

          setAddressAvailable(true);
          setShowAddressList(true);
          if (res.data && selectNewAddress == true) {
            setSelectedAddress(res.data[0]);
          } else if (res.data && updatedAddressId !== "") {
            res.data.map((address) => {
              if (address.id == updatedAddressId) {
                setSelectedAddress(address);
              }
            });
          } else if (res.data && selectedAddress == null) {
            res.data.map((address) => {
              if (address.is_default == 1) {
                setSelectedAddress(address);
              }
            });
          }
          setUpdatedAddressId("");
          setSelectNewAddress(false);
        }
        if (res.status == 401) {
          removeDataFromCookie("7H3K9R5P2Q", "/");
          removeDataFromCookie("isLoggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          contextObj.setInput("isLoggedIn", false);
          navigate(`${navigation.navigateToHomepage}`);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        }
      });
  };

  const getProductsDetails = async () => {
    const token = getDataFromCookies("7H3K9R5P2Q");
    if (contextObj.params.checkoutProducts.length == 0 || token == null) {
      window.history.back();
      return;
    }
    if (validShipmentToken) {
      await fetch(`${API_HOST}/${endpoints.checkoutPageShippingCost}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          product_ids: contextObj.params.checkoutProducts,
          shipping_address_id: selectedAddress.id,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (
            res.status == 404 &&
            res.message == "Something went wrong, please try again"
          ) {
            showToast("error", res.message);
            return window.history.back();
          }
          setLoading(false);
          if (
            res.status == 500 ||
            res.status == 501 ||
            res.status == 502 ||
            res.status == 503 ||
            (res.message && res.message.toLowerCase().includes("server error"))
          ) {
            if (res.status == 503) {
              setMaintainanceMode(true);
            } else {
              setMaintainanceMode(false);
            }
            contextObj.setInput("serviceUnavailable", true);
          } else {
            contextObj.setInput("serviceUnavailable", false);
          }

          let response = res.data;
          if (response && response.products) {
            setProducts(response.products);

            setDisabledPlaceOrder(false);
          }
          if (response.not_deliverable) {
            let array = [];
            response.products.forEach((product) => {
              if (product.shipping_cost == false) {
                array.push(product.product.id);
              }
            });

            setNotDeliverableProducts(array);

            showToast(
              "info",
              `${response.not_deliverable} ${
                response.not_deliverable > 1 ? "products" : "product"
              } can not be delivered.`
            );
          }
        });
    }
  };

  const handleEditAddress = () => {
    setShowEditAddressModal(true);
  };

  const getProductsDetailsWithoutShippingCharges = async () => {
    if (contextObj.params.checkoutProducts.length == 0) {
      window.history.back();
      return;
    }
    const payload = {
      product_ids: contextObj.params.checkoutProducts,
    };

    await fetch(`${API_HOST}/${endpoints.getGuestRecentlyViewedProducts}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (
          res.status == 500 ||
          res.status == 501 ||
          res.status == 502 ||
          res.status == 503 ||
          (res.message && res.message.toLowerCase().includes("server error"))
        ) {
          if (res.status == 503) {
            setMaintainanceMode(true);
          } else {
            setMaintainanceMode(false);
          }
          contextObj.setInput("serviceUnavailable", true);
        } else {
          contextObj.setInput("serviceUnavailable", false);
        }
        setProducts(res.data.products);
        setLoading(false);
        setDisabledPlaceOrder(true);
      });
  };

  useEffect(() => {
    if (fetchProductsWithoutAdd) {
      getProductsDetailsWithoutShippingCharges();
    }
  }, [fetchProductsWithoutAdd]);

  useEffect(() => {
    if (addressAvailable) {
      getProductsDetails();
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (contextObj.params.showAddressModal == false && defaultRender == false) {
      fetchUserAddressList();
    }
  }, [contextObj.params.showAddressModal]);

  useEffect(() => {
    const loggedIn = getDataFromCookies("isLoggedIn");
    if (loggedIn == true) {
      if (
        contextObj.params.navigation === "true" &&
        (contextObj.params.navigationFrom === "cartPage" ||
          contextObj.params.navigationFrom === "productDetails" ||
          contextObj.params.navigationFrom === "addUpdateAddress" ||
          contextObj.params.navigationFrom === "updateProfile")
      ) {
        if (contextObj.params.navigationFrom == "productDetails") {
          setNavigationFromProductDetails(true);
        }
        setdefaultRender(false);
        fetchUserAddressList();
      } else {
        window.history.back();
      }
    } else {
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      contextObj.setInput("isLoggedIn", false);
      navigate(`${navigation.navigateToHomepage}`);
    }
  }, []);

  useEffect(() => {
    if (showAddAddress) {
      document.body.style.overflow = "hidden";
      document.body.style.WebkitOverflowScrolling = "hidden";
      return () => {
        document.body.style.overflow = "auto";
        document.body.style.WebkitOverflowScrolling = "scroll";
      };
    }
  }, []);

  const setBrandNameByAPI = (brandId) => {
    if (brandData !== null) {
      let currentBrandData = {};
      const brandDataArray = brandData;
      if (brandDataArray !== undefined && brandDataArray !== null) {
        for (let i = 0; i < brandDataArray.length; i++) {
          if (brandDataArray[i].id == brandId) {
            currentBrandData = brandDataArray[i];
            break;
          }
        }
      }
      return currentBrandData.name;
    } else return "";
  };
  function filterArray(primaryArray, secondaryArray) {
    return primaryArray.filter((item) => !secondaryArray.includes(item));
  }

  const handlePlaceOrder = async () => {
    setDisablePlaceOrder(true);
    setPlaceOrderError("");
    if (selectedAddress !== undefined) {
      const token = getDataFromCookies("7H3K9R5P2Q");
      if (contextObj.params.checkoutProducts.length == 0 || token == null) {
        window.history.back();
        return;
      }
      let orderToPlace = filterArray(
        contextObj.params.checkoutProducts,
        notDeliverableProducts
      );

      if (orderToPlace.length == 0) {
        showToast(
          "error",
          `Oops! ${
            notDeliverableProducts.length == 1 ? "Product" : "Products"
          } in current order ${
            notDeliverableProducts.length == 1 ? "is" : "are"
          } not deliverable.`
        );
        setLoadingPlaceOrderBtn(false);
        return;
      }
      const placeOrderPayload = {
        product_ids: orderToPlace,
        shipping_address_id: `${selectedAddress.id}`,
      };

      await fetch(`${API_HOST}/${endpoints.buyerCheckout}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(placeOrderPayload),
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          setLoadingPlaceOrderBtn(false);
          setDisablePlaceOrder(false);
          if (
            res.status == 500 ||
            res.status == 501 ||
            res.status == 502 ||
            res.status == 503 ||
            (res.message && res.message.toLowerCase().includes("server error"))
          ) {
            if (res.status == 503) {
              setMaintainanceMode(true);
            } else {
              setMaintainanceMode(false);
            }
            contextObj.setInput("serviceUnavailable", true);
          } else {
            contextObj.setInput("serviceUnavailable", false);
          }
          if (res.status === 201) {
            showToast("success", Constants.alertMessages.alertForOrder);
            if (
              contextObj.params.cartLength > 0 &&
              !navigationFromProductDetails
            ) {
              contextObj.setInput(
                "cartLength",
                contextObj.params.cartLength -
                  contextObj.params.checkoutProducts.length
              );
            }
            contextObj.setInput("checkoutProducts", []);
            window.scrollTo(0, 0);
            setShowOrderConfirmation(true);
          } else if (res.status === 400) {
            let errors = res.errors;
            if (errors.product_ids) {
              showToast("error", errors.product_ids[0]);
            }
            if (errors.shipping_address_id) {
              showToast("error", errors.shipping_address_id[0]);
            }
          } else if (res.status == 401) {
            removeDataFromCookie("7H3K9R5P2Q", "/");
            removeDataFromCookie("isLoggedIn", "/");
            contextObj.setInput("navigation", "true");
            contextObj.setInput("navigationFrom", "sessionExpired");
            contextObj.setInput("isLoggedIn", false);
            navigate(`${navigation.navigateToHomepage}`);
          } else if (res.status == 404) {
            showToast(
              "error",
              Constants.alertMessages.alertForProductOutOfStock
            );
            navigate(`${navigation.navigateToNewCartPage}`);
          } else if (res.status == 422) {
            setPlaceOrderError(
              "1 or more product(s) are unavailable. Click again to proceed with available products."
            );
            removeDuplicates(res.data.failed_product_ids);
          }
          contextObj.setInput(
            "reFetchCartDetails",
            !contextObj.params.reFetchCartDetails
          );
        })
        .catch((error) => {
          console.error(error);
          if (error.message.includes("Failed to fetch")) {
            contextObj.setInput("serviceUnavailable", true);
          }
        });
    } else {
      showToast("info", Constants.alertMessages.alertForCheckoutAddress);
      setDisablePlaceOrder(false);
      setShowAddAddress(true);
      setShowUpdateProfile(false);
    }
  };

  // Function to format a number with commas
  function formatNumberWithCommas(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number;
  }

  return (
    <>
      {contextObj.params.serviceUnavailable == true ? (
        <ServiceUnavailable maintainanceMode={maintainanceMode} />
      ) : (
        <div
          className="pageParentDiv"
          // style={{
          //   marginTop: contextObj.params.topNavbarHeight,
          // }}
        >
          {contextObj.params.showAddressModal && (
            <>
              <AddressPage
                handleCloseModal={handleCloseModal}
                reFetchAddress={setReFetchAddress}
              />
            </>
          )}
          {loading ? (
            <div
              className="checkout-main-container-div"
              style={{
                marginTop:
                  window.innerWidth < 600
                    ? showFeatures.searchbar
                      ? "110px"
                      : "40px"
                    : undefined,
              }}
            >
              <div className="checkoutPage-cart-container">
                <CheckoutSkeleton />
              </div>
            </div>
          ) : (
            <>
              {products !== null && (
                <div
                  className="checkout-main-container-div"
                  // style={{
                  //   marginTop:
                  //     window.innerWidth < 600
                  //       ? showFeatures.searchbar
                  //         ? "110px"
                  //         : "40px"
                  //       : undefined,
                  // }}
                >
                  <div className="checkoutPage-cart-container">
                    <div className="checkout-cartContainerHolder">
                      {showOrderConfirmation ? (
                        <div
                          className="checkoutPageOrderConfirmationMainDiv"
                          id="orderPlacedConfirmation"
                        >
                          <div className="checkoutPageOrderConfirmationDiv">
                            <img
                              className="confirmationImageLogo"
                              src={Wright}
                              onError={(e) => {
                                e.target.src = placeHolderImage;
                              }}
                              alt="wright.webp"
                            />
                          </div>
                          <div className="checkoutPageOrderConfirmedDiv">
                            <h1>Order Placed Successfully</h1>
                          </div>
                          <div className="checkoutPageOrderConfirmationParaDiv">
                            <p className="checkoutPageOrderConfirmationParaForMobile">
                              Your order has been placed <br /> successfully.
                              you will receive an order
                              <br />
                              confirmation Email/SMS shortly with <br /> the
                              expected delivery date for your items.
                            </p>
                          </div>
                          <div className="checkoutPageOrderConfirmationButtonDiv">
                            <button
                              className="continueShoppingButton"
                              id="checkoutPageNavigateToHome"
                              onClick={() => {
                                navigate(`${navigation.navigateToHomepage}`);
                              }}
                            >
                              Continue Shopping
                            </button>
                            {showFeatures.myOrders ? (
                              <button
                                className="viewOrderButton"
                                id="checkoutPageViewOrderDetails"
                                onClick={() => {
                                  navigate(`${navigation.navigateToOrder}`);
                                }}
                              >
                                View Order Details
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="checkoutProductAddressDetails">
                            <div className="checkout-cart-container-1">
                              <div
                                className="checkout-cart-item-heading"
                                onClick={() => {
                                  setExpanded((prev) => ({
                                    ...prev,
                                    addresList: expanded.productList,
                                    productList: !expanded.productList,
                                  }));
                                }}
                              >
                                <p
                                  className="checkout-heading-txt"
                                  style={{
                                    cursor: "default",
                                  }}
                                >
                                  Product Summary (
                                  {contextObj.params.checkoutProducts.length})
                                </p>
                                <span
                                  className="changeDivStatus"
                                  id="changeStatusDivForProduct"
                                >
                                  {expanded.productList ? (
                                    <>
                                      <svg
                                        width="19"
                                        height="10"
                                        viewBox="0 0 19 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M9.5 4.36308L2.11078 12L-7.7486e-07 9.81846L9.5 -2.38419e-07L19 9.81846L16.8892 12L9.5 4.36308Z"
                                          fill="#8C8C8C"
                                        />
                                      </svg>
                                    </>
                                  ) : (
                                    <>
                                      <svg
                                        width="19"
                                        height="10"
                                        viewBox="0 0 19 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M9.5 7.63692L16.8892 0L19 2.18154L9.5 12L0 2.18154L2.11078 0L9.5 7.63692Z"
                                          fill="#8C8C8C"
                                        />
                                      </svg>
                                    </>
                                  )}
                                </span>
                              </div>
                              {expanded.productList ? (
                                <div className="">
                                  {products === null ? (
                                    <img
                                      src={require("../../Assets/rolling.svg")}
                                      alt="loading"
                                      className="loadingCartPage"
                                    />
                                  ) : (
                                    <>
                                      <div>
                                        {products.length > 0 &&
                                          products.map((product, index) => (
                                            <div>
                                              <div
                                                key={
                                                  addressAvailable
                                                    ? product.product.id
                                                    : product.id
                                                }
                                                className="checkout-container-having-productdetails"
                                              >
                                                <div
                                                  className="checkout-image-and-name-div"
                                                  onClick={() =>
                                                    navigate(
                                                      `${
                                                        navigation.navigateToProductList
                                                      }/${
                                                        addressAvailable
                                                          ? product.product.slug
                                                          : product.slug
                                                      }`
                                                    )
                                                  }
                                                >
                                                  <div className="checkout-image-container">
                                                    {addressAvailable ? (
                                                      // <img
                                                      //   src={
                                                      //     imgHost +
                                                      //     "/" +
                                                      //     product.product
                                                      //       .thumb_image
                                                      //   }
                                                      //   onError={(e) => {
                                                      //     e.target.src =
                                                      //       placeHolderImage;
                                                      //   }}
                                                      //   style={{
                                                      //     height: "100%",
                                                      //     width: "100%",
                                                      //     objectFit: "contain",
                                                      //   }}
                                                      //   alt=""
                                                      // />
                                                      <LazyImage
                                                        src={
                                                          imgHost +
                                                          "/" +
                                                          product.product
                                                            .thumb_image
                                                        }
                                                        style={{
                                                          height: "100%",
                                                          width: "100%",
                                                          objectFit: "contain",
                                                        }}
                                                        alt="image"
                                                      />
                                                    ) : (
                                                      // <img
                                                      //   className="checkout-image-container"
                                                      //   src={
                                                      //     imgHost +
                                                      //     "/" +
                                                      //     product.thumb_image
                                                      //   }
                                                      //   onError={(e) => {
                                                      //     e.target.src =
                                                      //       placeHolderImage;
                                                      //   }}
                                                      //   alt=""
                                                      // />
                                                      <LazyImage
                                                        className="checkout-image-container"
                                                        src={
                                                          imgHost +
                                                          "/" +
                                                          product.thumb_image
                                                        }
                                                        alt="image"
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="checkout-products-list-details">
                                                    <div className="checkout-brand-tag-buyer">
                                                      <div className="checkoutPageProductSummaryContainer">
                                                        <p
                                                          className="checkout-brand-p-tag WebViewOnly"
                                                          id={`checkoutProdBrand_${index}`}
                                                        >
                                                          {addressAvailable
                                                            ? setBrandNameByAPI(
                                                                product.product
                                                                  .brand_id
                                                              )
                                                            : setBrandNameByAPI(
                                                                product.brand_id
                                                              )}{" "}
                                                        </p>
                                                        <p
                                                          className="checkout-brand-name-description"
                                                          id={`checkoutProdName_${index}`}
                                                        >
                                                          {addressAvailable
                                                            ? product.product
                                                                .name
                                                            : product.name}
                                                        </p>
                                                      </div>
                                                      <div>
                                                        <div className="checkoutPriceAndDiscount">
                                                          <p className="checkoutpage-currentPrice">
                                                            <span className="">
                                                              ₹{" "}
                                                              {addressAvailable
                                                                ? formatNumberWithCommas(
                                                                    product
                                                                      .product
                                                                      .selling_price
                                                                  )
                                                                : formatNumberWithCommas(
                                                                    product.selling_price
                                                                  )}
                                                            </span>
                                                          </p>

                                                          <p className="checkoutpage-currentPrice ">
                                                            <span
                                                              className="checkout-ShippingCharges "
                                                              id="checkoutpageCurrentOff"
                                                            >
                                                              {addressAvailable
                                                                ? calculateOfferPercentage(
                                                                    product
                                                                      .product
                                                                      .current_market_price,
                                                                    product
                                                                      .product
                                                                      .selling_price
                                                                  )
                                                                : calculateOfferPercentage(
                                                                    product.current_market_price,
                                                                    product.selling_price
                                                                  )}
                                                              % off
                                                            </span>{" "}
                                                          </p>
                                                        </div>
                                                        <div>
                                                          <p className="checkoutpage-orignalPrice">
                                                            (Est. Original
                                                            price: ₹
                                                            <span className="OriginalPriceSpanTagProductList">
                                                              {addressAvailable
                                                                ? formatNumberWithCommas(
                                                                    product
                                                                      .product
                                                                      .current_market_price
                                                                  )
                                                                : formatNumberWithCommas(
                                                                    product.current_market_price
                                                                  )}
                                                            </span>
                                                            )
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {addressAvailable &&
                                                      product.shipping_cost && (
                                                        <div className=" mobileViewOnly">
                                                          <p className="price-paragraph-tag11 mobileViewCheckoutShippingCost">
                                                            Shipping :{" "}
                                                            <span className="checkout-ShippingCharges">
                                                              ₹{" "}
                                                              {addressAvailable
                                                                ? product.shipping_cost
                                                                : ""}
                                                            </span>
                                                          </p>
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="price-div-tag111 WebViewOnly">
                                                  <p className="checkoutpage-currentPrice">
                                                    <span className="">
                                                      ₹{" "}
                                                      {addressAvailable
                                                        ? formatNumberWithCommas(
                                                            product.product
                                                              .selling_price
                                                          )
                                                        : formatNumberWithCommas(
                                                            product.selling_price
                                                          )}
                                                    </span>
                                                  </p>
                                                  {addressAvailable &&
                                                    product.shipping_cost && (
                                                      <p className="price-paragraph-tag11">
                                                        Shipping :{" "}
                                                        <span className="checkout-ShippingCharges">
                                                          + ₹{" "}
                                                          {addressAvailable
                                                            ? product.shipping_cost
                                                            : ""}
                                                        </span>
                                                      </p>
                                                    )}
                                                </div>
                                              </div>

                                              {product.product &&
                                                product.product.outOfStock && (
                                                  <div>
                                                    <p className="checkoutOutOfStockProductTxt">
                                                      {" "}
                                                      Out of Stock
                                                    </p>
                                                  </div>
                                                )}
                                              {product.shipping_cost ==
                                                false && (
                                                <div>
                                                  <p className="checkoutOutOfStockProductTxt">
                                                    {" "}
                                                    This product is not
                                                    deliverable.
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                      </div>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>

                            <div
                              className="checkout-cart-container-1"
                              id="checkout-AddressDivContainer"
                            >
                              <div
                                className="checkout-cart-item-heading"
                                onClick={() => {
                                  setExpanded((prev) => ({
                                    ...prev,
                                    productList: expanded.addresList,
                                    addresList: !expanded.addresList,
                                  }));
                                }}
                                id="changeStatusDivForAddress"
                              >
                                <p
                                  className="checkout-heading-txt"
                                  style={{
                                    cursor: "default",
                                  }}
                                >
                                  Select Address
                                </p>
                                <span
                                  className="changeDivStatus"
                                  id="changeStatusDivForAddress"
                                >
                                  {expanded.addresList ? (
                                    <>
                                      <svg
                                        width="19"
                                        height="10"
                                        viewBox="0 0 19 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M9.5 4.36308L2.11078 12L-7.7486e-07 9.81846L9.5 -2.38419e-07L19 9.81846L16.8892 12L9.5 4.36308Z"
                                          fill="#8C8C8C"
                                        />
                                      </svg>
                                    </>
                                  ) : (
                                    <>
                                      <svg
                                        width="19"
                                        height="10"
                                        viewBox="0 0 19 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M9.5 7.63692L16.8892 0L19 2.18154L9.5 12L0 2.18154L2.11078 0L9.5 7.63692Z"
                                          fill="#8C8C8C"
                                        />
                                      </svg>
                                    </>
                                  )}
                                </span>
                              </div>
                              {expanded.addresList ? (
                                <>
                                  <div className="checkout-cart-container-1">
                                    <div className="borderline"></div>
                                    <div className="addressContainer">
                                      {showAddressList ? (
                                        <div>
                                          <div
                                            className="checkoutPageAddress-block"
                                            id="checkoutPageAddress-blockList"
                                          >
                                            {addressList &&
                                              addressList.map(
                                                (address, index) => (
                                                  <>
                                                    <div
                                                      className={`checkout-addressContainer ${
                                                        address.id ==
                                                        selectedAddress.id
                                                          ? "checkout-addressContainer-selectedAddress"
                                                          : ""
                                                      }`}
                                                      key={address.id}
                                                    >
                                                      <div className="checkoutNameAddressDetailsContainer">
                                                        <div
                                                          id={`checkoutPageAddressList_${index}`}
                                                          className="checkoutRadioButtonContainer  checkout-addressContainerComponent selectAddressCheckboxContainer"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            if (
                                                              selectedAddress !==
                                                              address
                                                            ) {
                                                              setLoading(true);
                                                              setSelectedAddress(
                                                                address
                                                              );
                                                            }
                                                          }}
                                                          tabIndex={0}
                                                          onKeyDown={(e) => {
                                                            if (
                                                              e.key === "Enter"
                                                            ) {
                                                              e.preventDefault();
                                                              e.stopPropagation();
                                                              if (
                                                                selectedAddress !==
                                                                address
                                                              ) {
                                                                setLoading(
                                                                  true
                                                                );
                                                                setSelectedAddress(
                                                                  address
                                                                );
                                                              }
                                                            }
                                                          }}
                                                        >
                                                          <CheckoutPageRadioBtn
                                                            isChecked={
                                                              address.id ===
                                                              selectedAddress.id
                                                            }
                                                          />
                                                        </div>
                                                        <div className="WebViewOnly">
                                                          <div className="checkoutNameAddressContainer  checkout-addressContainerComponent ">
                                                            <div
                                                              style={{
                                                                width: "90%",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              <div className="checkoutAddressNameNumber">
                                                                {address &&
                                                                  address.contact_person_name !==
                                                                    null && (
                                                                    <span
                                                                      id={`checkoutPageAddressName_${index}`}
                                                                    >
                                                                      {
                                                                        address.contact_person_name
                                                                      }{" "}
                                                                    </span>
                                                                  )}

                                                                {address &&
                                                                  address.contact_person_phone !==
                                                                    null && (
                                                                    <span
                                                                      id={`checkoutPageAddressNumber_${index}`}
                                                                    >
                                                                      {
                                                                        address.contact_person_phone
                                                                      }{" "}
                                                                    </span>
                                                                  )}

                                                                <span
                                                                  className="checkoutPageAddressPageAddressType"
                                                                  id={`checkoutPageAddressPageAddressType_${index}`}
                                                                >
                                                                  {address &&
                                                                  address.address_type !==
                                                                    null
                                                                    ? `${address.address_type.toUpperCase()}`
                                                                    : ""}
                                                                </span>
                                                              </div>
                                                              <p className="checkoutPageAddressDetailsTxt">
                                                                {address &&
                                                                address.address_1 !==
                                                                  null
                                                                  ? `${address.address_1}, `
                                                                  : ""}

                                                                {address &&
                                                                address.address_2 !==
                                                                  null
                                                                  ? `${address.address_2}, `
                                                                  : ""}
                                                                {address &&
                                                                  address.city !==
                                                                    null && (
                                                                    <span
                                                                      id={`checkoutPageAddressCity_${index}`}
                                                                    >
                                                                      {
                                                                        address.city
                                                                      }{" "}
                                                                    </span>
                                                                  )}

                                                                {address &&
                                                                  address.state !==
                                                                    null && (
                                                                    <span
                                                                      id={`checkoutPageAddressState_${index}`}
                                                                    >
                                                                      {
                                                                        address.state
                                                                      }
                                                                      {" - "}
                                                                    </span>
                                                                  )}

                                                                {address &&
                                                                  address.pincode !==
                                                                    null && (
                                                                    <span
                                                                      id={`checkoutPageAddressPinCode_${index}`}
                                                                    >
                                                                      {
                                                                        address.pincode
                                                                      }
                                                                    </span>
                                                                  )}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="mobileViewOnly">
                                                          <div className="checkoutAddressNameNumber">
                                                            {address.contact_person_name !==
                                                            null
                                                              ? `${address.contact_person_name}, `
                                                              : ""}

                                                            {address.contact_person_phone !==
                                                            null
                                                              ? `${address.contact_person_phone} `
                                                              : ""}

                                                            <span className="checkoutPageAddressPageAddressType">
                                                              {address.address_type !==
                                                              null
                                                                ? `${address.address_type.toUpperCase()}`
                                                                : ""}
                                                            </span>
                                                          </div>
                                                          <p className="checkoutPageAddressDetailsTxt">
                                                            {address.address_1 !==
                                                            null
                                                              ? `${address.address_1}, `
                                                              : ""}

                                                            {address.address_2 !==
                                                            null
                                                              ? `${address.address_2}, `
                                                              : ""}
                                                            {address.city !==
                                                            null
                                                              ? `${address.city}, `
                                                              : ""}

                                                            {address.state !==
                                                            null
                                                              ? `${address.state} - `
                                                              : ""}

                                                            {address.pincode !==
                                                            null
                                                              ? `${address.pincode}`
                                                              : ""}
                                                          </p>
                                                        </div>
                                                      </div>
                                                      {address.id ==
                                                      selectedAddress.id ? (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            marginRight: "5px",
                                                          }}
                                                        >
                                                          <button
                                                            id={`checkoutPageEditAddress_${index}`}
                                                            className="checkoutAddressEditBtn  checkout-addressContainerComponent cart-delete-buttonTab-index"
                                                            style={{
                                                              opacity:
                                                                address.id ==
                                                                selectedAddress.id
                                                                  ? 1
                                                                  : 0,
                                                            }}
                                                            onClick={(e) => {
                                                              if (
                                                                address.id ==
                                                                selectedAddress.id
                                                              ) {
                                                                setSelectedAddress(
                                                                  address
                                                                );
                                                                setUpdatedAddressId(
                                                                  address.id
                                                                );
                                                                handleEditAddress();
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                              }
                                                            }}
                                                          >
                                                            <span> Edit</span>
                                                          </button>
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  </>
                                                )
                                              )}
                                          </div>
                                          <div
                                            className={
                                              disableAddAddress
                                                ? "checkoutPageAddNewAddress disabledElement"
                                                : "checkoutPageAddNewAddress"
                                            }
                                          >
                                            <button
                                              className="checkoutPageAddAddressBtn cart-delete-buttonTab-index"
                                              id="checkoutPageAddAddressBtn"
                                              onClick={() => {
                                                if (!disableAddAddress) {
                                                  handleAddAddress();
                                                }
                                              }}
                                              style={{
                                                cursor: disableAddAddress
                                                  ? "not-allowed"
                                                  : "pointer",
                                              }}
                                            >
                                              <svg
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M6 1V11"
                                                  stroke="#379D75"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                                <path
                                                  d="M1 6H11"
                                                  stroke="#379D75"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                              <span
                                                style={{ marginLeft: "10px" }}
                                              >
                                                {" "}
                                                New Address
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <div
                                            className="checkoutPageAddress-block"
                                            id="NoAddressAvailableInCheckout"
                                          >
                                            <div className="No_AddressFoundDiv">
                                              <img
                                                className=""
                                                src={AddressEmptyImage}
                                                onError={(e) => {
                                                  e.target.src =
                                                    placeHolderImage;
                                                }}
                                                alt="Wishlist.webp"
                                              />
                                              <h4 className="wishlistHeadingElement">
                                                Your address list is empty!
                                              </h4>
                                              <li className="wishlistEmptyline">
                                                Ready to Place Order? Add your
                                                shipping address to complete
                                                your order!
                                              </li>
                                              <button
                                                className="ShopNowButtonWishlist"
                                                onClick={() => {
                                                  handleAddAddress();
                                                }}
                                              >
                                                {" "}
                                                + New Address{" "}
                                              </button>
                                            </div>
                                            {showUpdateProfile ? (
                                              <>
                                                <div className="ModalContainer">
                                                  <div
                                                    className="ModalContent"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      handleUpdateCancel();
                                                    }}
                                                  >
                                                    <div
                                                      className="Address_update_confirmation_popup"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                      }}
                                                    >
                                                      <div
                                                        className="Address_Profile_crossbutton_Div"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <svg
                                                          onClick={
                                                            handleUpdateCancel
                                                          }
                                                          width="12"
                                                          height="12"
                                                          viewBox="0 0 8 8"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M4.93909 3.99943L7.80299 1.14179C7.92841 1.01635 7.99886 0.846232 7.99886 0.668843C7.99886 0.491455 7.92841 0.321332 7.80299 0.1959C7.67758 0.0704673 7.50748 0 7.33012 0C7.15275 0 6.98265 0.0704673 6.85724 0.1959L4 3.06021L1.14276 0.1959C1.01735 0.0704673 0.847248 -1.32164e-09 0.669885 0C0.492522 1.32165e-09 0.322423 0.0704673 0.197008 0.1959C0.0715939 0.321332 0.00113664 0.491455 0.00113664 0.668843C0.00113663 0.846232 0.0715939 1.01635 0.197008 1.14179L3.06091 3.99943L0.197008 6.85708C0.134583 6.919 0.085035 6.99267 0.0512219 7.07385C0.0174088 7.15502 0 7.24208 0 7.33002C0 7.41796 0.0174088 7.50502 0.0512219 7.58619C0.085035 7.66737 0.134583 7.74104 0.197008 7.80296C0.258924 7.8654 0.332587 7.91495 0.413748 7.94877C0.494909 7.98259 0.581962 8 0.669885 8C0.757808 8 0.844861 7.98259 0.926022 7.94877C1.00718 7.91495 1.08085 7.8654 1.14276 7.80296L4 4.93866L6.85724 7.80296C6.91916 7.8654 6.99282 7.91495 7.07398 7.94877C7.15514 7.98259 7.24219 8 7.33012 8C7.41804 8 7.50509 7.98259 7.58625 7.94877C7.66741 7.91495 7.74108 7.8654 7.80299 7.80296C7.86542 7.74104 7.91497 7.66737 7.94878 7.58619C7.98259 7.50502 8 7.41796 8 7.33002C8 7.24208 7.98259 7.15502 7.94878 7.07385C7.91497 6.99267 7.86542 6.919 7.80299 6.85708L4.93909 3.99943Z"
                                                            fill="#A3A3A3"
                                                          />
                                                        </svg>
                                                      </div>
                                                      <h4 className="Address_Update_Profile_Heading">
                                                        Update Your Profile
                                                      </h4>
                                                      <p className="Address_page_Update_Profile_Paragraph">
                                                        To create new address,
                                                        first you have to update
                                                        your profile with all
                                                        the required details.
                                                      </p>
                                                      <div className="Address_Page_Update_Profile_Button_Div">
                                                        <button
                                                          className="Address_Page_UpdateProfile_button3"
                                                          onClick={() => {
                                                            contextObj.setInput(
                                                              "updateProfileFromCheckout",
                                                              true
                                                            );
                                                            navigate(
                                                              `${navigation.navigateToProfileInfo}`
                                                            );
                                                          }}
                                                        >
                                                          Update Now
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  {showEditAddressModal ? (
                                    <>
                                      <UpdateAddressPage
                                        handleCloseEditModal={
                                          handleCloseEditModal
                                        }
                                        address={selectedAddress}
                                        reFetchAddress={setReFetchAddress}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {addressList.length > 0 ? (
                                    <div className="checkout-cart-container-1">
                                      <div className="borderline"></div>
                                      <div className="defaultAddressDivCheckoutPage">
                                        <div className="checkoutAddressNameNumber">
                                          {selectedAddress &&
                                          selectedAddress.contact_person_name !==
                                            null
                                            ? `${selectedAddress.contact_person_name}, `
                                            : ""}

                                          {selectedAddress &&
                                          selectedAddress.contact_person_phone !==
                                            null
                                            ? `${selectedAddress.contact_person_phone} `
                                            : ""}

                                          <span className="checkoutPageAddressPageAddressType">
                                            {selectedAddress &&
                                            selectedAddress.address_type !==
                                              null
                                              ? `${selectedAddress.address_type.toUpperCase()}`
                                              : ""}
                                          </span>
                                        </div>
                                        <p className="checkoutPageAddressDetailsTxt">
                                          {selectedAddress &&
                                          selectedAddress.address_1 !== null
                                            ? `${selectedAddress.address_1}, `
                                            : ""}

                                          {selectedAddress &&
                                          selectedAddress.address_2 !== null
                                            ? `${selectedAddress.address_2}, `
                                            : ""}
                                          {selectedAddress &&
                                          selectedAddress.city !== null
                                            ? `${selectedAddress.city}, `
                                            : ""}

                                          {selectedAddress &&
                                          selectedAddress.state !== null
                                            ? `${selectedAddress.state} - `
                                            : ""}

                                          {selectedAddress &&
                                          selectedAddress.pincode !== null
                                            ? `${selectedAddress.pincode}`
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              addressAvailable
                                ? "checkoutProductPriceSummaryDetails"
                                : "checkoutProductPriceSummaryDetailsWithoutAddress"
                            }
                          >
                            <div
                              className="checkoutProductPriceSummaryDetails-charges "
                              id="checkoutProductPriceSummaryDetails-charges"
                            >
                              <p className="checkoutPageAddressDetailsTxt checkoutPriceSummaryHeading">
                                Price Details
                              </p>
                            </div>
                            <div id="checkoutProductPriceSummaryDetails-chargesContainer ">
                              <div
                                className="checkoutProductPriceSummaryDetails-charges"
                                id="checkoutProductOrderAmount"
                              >
                                <div>
                                  {" "}
                                  <p className="checkoutPageAddressDetailsTxt">
                                    Order Amount
                                  </p>
                                </div>
                                <div>
                                  <p className="checkoutPageAddressDetailsTxt">
                                    + ₹ {formatNumberWithCommas(SubtotalPrice)}
                                  </p>
                                </div>
                              </div>
                              {addressAvailable && (
                                <>
                                  <div
                                    className="checkoutProductPriceSummaryDetails-charges"
                                    id="checkoutProductShippingCharge"
                                  >
                                    <div>
                                      {" "}
                                      <p className="checkoutPageAddressDetailsTxt">
                                        Shipping Charge
                                      </p>
                                    </div>
                                    <div>
                                      {" "}
                                      <p className="checkoutPageAddressDetailsTxt">
                                        + ₹{" "}
                                        {formatNumberWithCommas(
                                          totalShippingCharges
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="checkoutTotalcharges">
                              <div
                                className="checkoutProductPriceSummaryDetails-charges"
                                style={{ margin: "0", marginTop: "10px" }}
                                id="checkoutProductTotalCharge"
                              >
                                <div>
                                  <p
                                    className="checkoutPageAddressDetailsTxt "
                                    style={{
                                      fontWeight: "600",
                                    }}
                                  >
                                    Total
                                  </p>
                                </div>
                                <div>
                                  <p
                                    className="checkoutPageAddressDetailsTxt "
                                    style={{
                                      fontWeight: "600",
                                    }}
                                  >
                                    ₹{" "}
                                    {formatNumberWithCommas(
                                      totalShippingCharges + SubtotalPrice
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>

                            {addressAvailable && totalSavings > 0 && (
                              <div className="checkoutPageDiscountDiv">
                                <p className="checkoutPageYayText">
                                  Yay! You saved ₹
                                  {formatNumberWithCommas(totalSavings)} on this
                                  order.
                                </p>
                              </div>
                            )}
                            <div className="checkoutPlaceOrderContainer">
                              <p className="checkoutPageAddressDetailsTxt checkoutPageAddressDetailsTxt-infoTxt">
                                Clicking on ‘Place Order’ will not deduct any
                                money.
                              </p>

                              <button
                                id="checkoutPagePlaceOrderBtn"
                                style={{
                                  opacity: disabledPlaceOrder ? 0.5 : 1,
                                  cursor: disabledPlaceOrder
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                className={
                                  disabledPlaceOrder
                                    ? "disabledCheckoutPlaceOrderBtn cart-delete-buttonTab-index"
                                    : "checkoutPlaceOrderBtn cart-delete-buttonTab-index"
                                }
                                onClick={async () => {
                                  if (
                                    contextObj.params.checkoutProducts.length ==
                                    0
                                  ) {
                                    navigate("/products");
                                  } else {
                                    if (
                                      !expanded.productList &&
                                      notDeliverableProducts.length !== 0
                                    ) {
                                      setExpanded((prev) => ({
                                        ...prev,
                                        addresList: false,
                                        productList: true,
                                      }));
                                    } else {
                                      if (
                                        !disabledPlaceOrder &&
                                        !loadingPlaceOrderBtn
                                      ) {
                                        setLoadingPlaceOrderBtn(true);
                                        await handlePlaceOrder();
                                      }
                                    }
                                  }
                                }}
                              >
                                {loadingPlaceOrderBtn ? (
                                  <img
                                    src={loadingImgPath}
                                    alt="Loading"
                                    className="disabledBtn-Loading"
                                  />
                                ) : contextObj.params.checkoutProducts.length ==
                                  0 ? (
                                  "Continue Shopping"
                                ) : !expanded.productList &&
                                  notDeliverableProducts.length !== 0 ? (
                                  "Continue"
                                ) : (
                                  "Place Order"
                                )}
                              </button>

                              {placeOrderError !== "" && (
                                <p className="checkoutPageAddressDetailsTxt checkoutPagePlaceOrderErrorMsg">
                                  {placeOrderError}
                                </p>
                              )}

                              <p className="checkoutPageAddressDetailsTxt checkoutPageAddressDetailsTxt-infoTxt WebViewOnly">
                                By placing your order, you agree to JOYREJOY's{" "}
                                <span
                                  onClick={() => {
                                    window.open("/privacy-policy", "_blank");
                                  }}
                                  style={{
                                    color: "#3366cc",
                                    cursor: "pointer",
                                  }}
                                >
                                  privacy policy
                                </span>{" "}
                                and{" "}
                                <span
                                  onClick={() => {
                                    window.open("/terms-of-use", "_blank");
                                  }}
                                  style={{
                                    color: "#3366cc",
                                    cursor: "pointer",
                                  }}
                                >
                                  terms of use
                                </span>
                                .
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CheckoutPage;
